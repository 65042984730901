import { FC } from "react";
import cssClass from "classnames";

type TextButtonProps = {
  className ?: string;
  color ?: string;
  disabled ?: boolean;
  onClick : () => void;
};

export const TextButton : FC<TextButtonProps> = ({ children, className, color = "text-sky-600", ...props }) => {
  return (
    <button
      {...props}
      type="button"
      className={cssClass(className, color, "underline outline-none underline-offset-2 ")}
    >
      {children}
    </button>
  );
};
