import { FC, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { UserSteps } from "graphql/types";
import { setupI18N } from "i18n/web";
import { CandidacyProvider } from "state/candidacy";
import { initialState, reducerCandidacy } from "state/candidacy/reducer";
import { AuthenticationProvider } from "components/atoms/auth0-provider";
import { CurrentStep as Root } from "components/pages/step-current";
import { StepLocation as Step1 } from "components/pages/step-location";
import { StepHomePhotos as Step2 } from "components/pages/step-home-photos";
import { StepHomeAccessibility as Step3 } from "components/pages/step-home-accessibility";
import { StepHomeInfo as Step4 } from "components/pages/step-home-info";
import { StepCusinePhotos as Step5 } from "components/pages/step-cusine-photos";
import { StepCusineInfo as Step6 } from "components/pages/step-cusine-info";
import { StepProposals as Step7 } from "components/pages/step-proposals";
import { StepProfile as Step8 } from "components/pages/step-profile";
import { StepCertificates as Step9 } from "components/pages/step-certificates";
import { StepExtra as Step10 } from "components/pages/step-extra";
import { MAP_STEP_ROUTE } from "routes";
import { useGAPageViews } from "../hooks/use-ga-pageviews";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import MicrosoftUET from "../analytics/MicrosoftUET";
import LinkedInTag from "react-linkedin-insight";
import { clarity } from "react-microsoft-clarity";
import { useGetCurrentStep } from "hooks/use-get-current-step";

setupI18N();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const CurrentStep = withAuthenticationRequired(Root);

const StepLocation = withAuthenticationRequired(Step1);
const StepHomePhotos = withAuthenticationRequired(Step2);
const StepHomeAccessibility = withAuthenticationRequired(Step3);
const StepHomeInfo = withAuthenticationRequired(Step4);
const StepCusinePhotos = withAuthenticationRequired(Step5);
const StepCusineInfo = withAuthenticationRequired(Step6);
const StepProposals = withAuthenticationRequired(Step7);
const StepProfile = withAuthenticationRequired(Step8);
const StepCertificates = withAuthenticationRequired(Step9);
const StepExtra = withAuthenticationRequired(Step10);

ReactGA.initialize([
  {
    trackingId: window.ENV.REACT_APP_GOOGLE_ANALYTICS_PROPERTY_ID,
  },
  {
    trackingId: window.ENV.REACT_APP_GOOGLE_ADS_PROPERTY_ID,
    gtagOptions: { allow_enhanced_conversions: true },
  },
]);
ReactGA.send("pageview");

ReactPixel.init(window.ENV.REACT_APP_FACEBOOK_PIXEL_ID);
ReactPixel.pageView();

MicrosoftUET.initialize(window.ENV.REACT_APP_MICROSOFT_UET);
MicrosoftUET.trackPageview();

LinkedInTag.init(window.ENV.REACT_APP_LINKEDIN_INSIGHT_ID);

export enum CandidacyTypeEnum {
  CESARINA = "cesarina",
  COMPANY = "company",
}
export type CandidacyType =
  | CandidacyTypeEnum.CESARINA
  | CandidacyTypeEnum.COMPANY;
interface Props {
  type: CandidacyType;
}

clarity.init(window.ENV.REACT_APP_CLARITY_ID);

const PageCandidatura: FC<Props> = ({ type }) => {
  useGAPageViews();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthenticationProvider>
        <RoutesManager type={type} />
      </AuthenticationProvider>
    </QueryClientProvider>
  );
};

const RoutesManager: FC<Props> = ({ type }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data, isLoading } = useGetCurrentStep(type);

  useEffect(() => {
    if (
      data &&
      ((type !== data?.candidacyType && data?.candidacyType != null) ||
        location.pathname === "/")
    ) {
      navigate(
        `${
          data.candidacyType === CandidacyTypeEnum.COMPANY
            ? `/${data.candidacyType}`
            : ""
        }${MAP_STEP_ROUTE.get(data.currentStep)}` + location.search
      );
    }

    if (data && location.pathname.replace(/\/$/, "") === "/company") {
      navigate(
        `/company${MAP_STEP_ROUTE.get(data.currentStep)}` + location.search
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, type]);

  if (isLoading) {
    return <CurrentStep />;
  }

  const candidacyType = data.candidacyType ?? type;

  return (
    <CandidacyProvider
      reducer={reducerCandidacy}
      initialState={{
        ...initialState,
        type: candidacyType as CandidacyType,
      }}
    >
      <Routes>
        <Route
          path={MAP_STEP_ROUTE.get(UserSteps.Location)}
          element={<StepLocation />}
        />
        <Route
          path={MAP_STEP_ROUTE.get(UserSteps.Home)}
          element={<StepHomePhotos />}
        />
        <Route
          path={MAP_STEP_ROUTE.get(UserSteps.Accessibility)}
          element={<StepHomeAccessibility />}
        />
        <Route
          path={MAP_STEP_ROUTE.get(UserSteps.Homeinfo)}
          element={<StepHomeInfo />}
        />
        {candidacyType === CandidacyTypeEnum.CESARINA && (
          <>
            <Route
              path={MAP_STEP_ROUTE.get(UserSteps.Cusine)}
              element={<StepCusinePhotos />}
            />
            <Route
              path={MAP_STEP_ROUTE.get(UserSteps.Cusineinfo)}
              element={<StepCusineInfo />}
            />
            <Route
              path={MAP_STEP_ROUTE.get(UserSteps.Proposals)}
              element={<StepProposals />}
            />
          </>
        )}
        <Route
          path={MAP_STEP_ROUTE.get(UserSteps.Profile)}
          element={<StepProfile />}
        />
        {candidacyType === CandidacyTypeEnum.CESARINA && (
          <>
            <Route
              path={MAP_STEP_ROUTE.get(UserSteps.Certificates)}
              element={<StepCertificates />}
            />
            <Route
              path={MAP_STEP_ROUTE.get(UserSteps.Extra)}
              element={<StepExtra />}
            />
          </>
        )}
        <Route path="*" element={<CurrentStep />} />
      </Routes>
    </CandidacyProvider>
  );
};

export default PageCandidatura;
