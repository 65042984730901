import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ChangeRequest } from "graphql/types";

import { Page, PageProps } from "components/organisms/layout-candidatura";
import {
  TitleSection,
  PageTitle,
} from "components/organisms/layout-candidatura/title";
import { Feedback } from "components/atoms/feedback";
import { ReadonlyWarning } from "components/atoms/warning";
import { useCandidacyCompanyFlow } from "state/candidacy";
import { addCompanySubstringToKey } from "utils/steps";

type LayoutProps = Omit<PageProps, "HighlightedText"> & {
  errors?: ChangeRequest[];
  readOnly?: boolean;
};

export const Layout: FC<LayoutProps> = ({
  children,
  errors,
  readOnly,
  ...props
}) => {
  const { t } = useTranslation();
  const isCompanyFlow = useCandidacyCompanyFlow();

  return (
    <Page
      {...props}
      HighlightedText={
        <div className="flex flex-col py-6 pl-2 space-y-3 text-white rounded-r-sm bg-tblack">
          <span className="text-6xl">
            {t(
              addCompanySubstringToKey("step-profile.panelTitle", isCompanyFlow)
            )}
          </span>
          <span className="text-4xl">
            {t(
              addCompanySubstringToKey(
                "step-profile.panelSubtitle",
                isCompanyFlow
              )
            )}
          </span>
        </div>
      }
    >
      <TitleSection>
        <PageTitle>
          {t(addCompanySubstringToKey("step-profile.title", isCompanyFlow))}
        </PageTitle>
        <p>{t("step-profile.description")}</p>
      </TitleSection>
      {readOnly && (
        <ReadonlyWarning className="mb-8" status={props.calculatedStatus} />
      )}
      {errors?.length > 0 && (
        <Feedback className="mb-8">{errors[0].message}</Feedback>
      )}
      {children}
    </Page>
  );
};
