import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ChangeRequest } from "graphql/types";

import { Page, PageProps } from "components/organisms/layout-candidatura";
import { TitleSection, PageTitle } from "components/organisms/layout-candidatura/title";
import { Feedback } from "components/atoms/feedback";
import { ReadonlyWarning } from "components/atoms/warning";

type LayoutProps = Omit<PageProps, "HighlightedText"> & {
  errors ?: ChangeRequest[];
  readOnly ?: boolean;
};

export const Layout : FC<LayoutProps> = ({ children, errors, readOnly, ...props }) => {
  const { t } = useTranslation();
  return (
    <Page
      {...props}
      HighlightedText={
        <div className="flex flex-col py-6 pl-2 space-y-3 text-white rounded-r-sm bg-tblack">
          <span className="text-6xl">{t("step-cusine-photos.panelTitle")}</span>
          <span className="text-4xl">{t("step-cusine-photos.panelSubtitle")}</span>
        </div>
      }
    >
      <TitleSection>
        <PageTitle>{t("step-cusine-photos.title")}</PageTitle>
        <p>{t("step-cusine-photos.description")}</p>
      </TitleSection>
      {readOnly && <ReadonlyWarning className="mb-8" status={props.calculatedStatus} />}
      {errors?.length > 0 && <Feedback className="mb-8">{errors[0].message}</Feedback>}
      {children}
    </Page>
  );
};
