import { FC } from "react";

export const FieldCol : FC = ({ children }) => {
  return (
    <div className="flex flex-col space-y-8">
      {children}
    </div>
  );
};

export const FieldRow : FC = ({ children }) => {
  return (
    <div className="flex flex-col flex-wrap space-y-5 lg:flex-row lg:items-start lg:space-x-5 lg:space-y-0">
      {children}
    </div>
  );
};
