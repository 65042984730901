import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import ReactGA from "react-ga4";

export const useGAPageViews = () => {
  let location = useLocation();
  useEffect(
    () => {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    },
    [location]
  );
};
