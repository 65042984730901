import { useTranslation } from "react-i18next";

export const useBooleanOptions = () => {
  const { t } = useTranslation();
  return [
    {
      label: t("ctaSelect"),
      value: "",
    },
    {
      label: t("yes"),
      value: "yes",
    },
    {
      label: t("no"),
      value: "no",
    },
  ];
};
