import { FC } from "react";
import { useTranslation } from "react-i18next";

type GalleryProps = {
  galleryUrl : string;
  onOpen ?: () => void;
  size ?: string;
};

const getGalleryItemsCount = (galleryUrl : string) : number => {
  const rxCount = /~([\d]+)/;
  const match = galleryUrl.match(rxCount);
  if (!match) {
    return 0;
  }
  const [, count] = match;
  return Number(count);
};

const getTransform = ({ size }) => {
  let transforms = "";
  if (size) {
    transforms += `-/smart_resize/${size}/`;
  }
  return transforms;
};

export const Gallery : FC<GalleryProps> = ({ galleryUrl, onOpen, size }) => {
  const { t } = useTranslation();

  if (!galleryUrl) {
    return null;
  }

  const countItems = getGalleryItemsCount(galleryUrl);
  if (countItems === 0) {
    return null;
  }

  const transforms = getTransform({ size });

  return (
    <div className="flex flex-col items-center space-y-5">
      <div className="flex flex-wrap">
        {Array.from({ length: countItems },
          (_, idx) => {
            return (
              <div key={idx} className="relative p-1">
                {idx === 0 && <span className="absolute px-3 py-1 text-xs rounded-md top-2 left-2 bg-overlay">Immagine di copertina</span>}
                <img alt="" className="rounded-sm" src={`${galleryUrl}nth/${idx}/${transforms}`} />
              </div>
            );
          })}
      </div>
      {typeof onOpen == "function" && (
        <button className="px-3 py-2 border rounded text-brandYellow border-brandYellow" type="button" onClick={onOpen}>
          {t("ctaEditImages")}
        </button>
      )}
    </div>
  );
};
