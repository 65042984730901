import { FC } from "react";
import cssClass from "classnames";

type ErrorProps = {
  className ?: string;
};

export const Error : FC<ErrorProps> = ({ className, children }) => {
  return (
    <div className={cssClass(className, "text-sm")}>
      <span className="text-red-700">{children}</span>
    </div>
  );
};
