import {IAnalyticsTracker, SetUserInput} from "./IAnalyticsTracker";
import {GoogleAnalyticsTracker} from "./GoogleAnalyticsTracker";
import {AdwordsTracker} from "./AdwordsTracker";
import {PosthogTracker} from "./PosthogTracker";
import {FacebookPixelTracker} from "./FacebookPixelTracker";
import {MicrosoftTracker} from "./MicrosoftTracker";
import {LinkedinInsightTracker} from "./LinkedinInsightTracker";

export class AnalyticsTrackerComposite implements IAnalyticsTracker {
  private static self = new AnalyticsTrackerComposite();

  public static instance(): AnalyticsTrackerComposite {
    return this.self;
  }

  private constructor(
    private readonly trackers: IAnalyticsTracker[] = [
      new GoogleAnalyticsTracker(),
      new AdwordsTracker(),
      new PosthogTracker(),
      new MicrosoftTracker(),
      new FacebookPixelTracker(),
      new LinkedinInsightTracker(),
    ]
  ) {
  }

  async setUser(data: SetUserInput): Promise<void> {
    try {
      await Promise.all(this.trackers.map(t => t.setUser(data)));
    } catch (e) {
      //We don't want to break the flow if the analytics fail
      console.error(e);
    }
  }

  locationStepCompleted(): Promise<void> {
    return this.callAllTrackers("locationStepCompleted");
  }

  homePhotosStepCompleted(): Promise<void> {
    return this.callAllTrackers("homePhotosStepCompleted");
  }

  homeAccessibilityStepCompleted(): Promise<void> {
    return this.callAllTrackers("homeAccessibilityStepCompleted");
  }

  homeInfoStepCompleted(): Promise<void> {
    return this.callAllTrackers("homeInfoStepCompleted");
  }

  cuisinePhotosStepCompleted(): Promise<void> {
    return this.callAllTrackers("cuisinePhotosStepCompleted");
  }

  cuisineInfoStepCompleted(): Promise<void> {
    return this.callAllTrackers("cuisineInfoStepCompleted");
  }

  proposalStepCompleted(): Promise<void> {
    return this.callAllTrackers("proposalStepCompleted");
  }

  profileStepCompleted(): Promise<void> {
    return this.callAllTrackers("profileStepCompleted");
  }

  certificatesStepCompleted(): Promise<void> {
    return this.callAllTrackers("certificatesStepCompleted");
  }

  extraEarnStepCompleted(): Promise<void> {
    return this.callAllTrackers("extraEarnStepCompleted");
  }

  beginCandidacy(): Promise<void> {
    return this.callAllTrackers("beginCandidacy");
  }

  endCandidacy(): Promise<void> {
    return this.callAllTrackers("endCandidacy");
  }

  private async callAllTrackers(method: keyof Omit<IAnalyticsTracker, "setUser">): Promise<void> {
    try {
      await Promise.all(this.trackers.map(t => t[method]()));
    } catch (e) {
      //We don't want to break the flow if the analytics fail
      console.error(e);
    }
  }

  candidacyApproved(): Promise<void> {
    return this.callAllTrackers("candidacyApproved");
  }

  candidacyRejected(): Promise<void> {
    return this.callAllTrackers("candidacyRejected");
  }
}
