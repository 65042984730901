import { Component } from "react";

type SafeBoundaryProps = {
  children : React.ReactNode;
};

type SafeBoundaryState = {
  error : Error;
};

export class SafeBoundary extends Component<SafeBoundaryProps, SafeBoundaryState> {
  constructor (props : SafeBoundaryProps) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch (error : Error) {
    this.setState({ error: error });
  }

  render () {
    if (this.state.error) {
      return (
        <div className="p-2 m-1 text-sm text-red-900 bg-red-100 border border-red-900 rounded">
          An error occurred.
          {
            window.location.hostname === "localhost" && (
              <pre className="mt-1 text-xs whitespace-normal">
                {this.state.error.stack}
              </pre>
            )
          }
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}
