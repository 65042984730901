import { CandidacyState } from ".";
import { CandidacyAction } from "./actions";
import { SET_COMPLETED } from "./actions/set-completed";

export const initialState: CandidacyState = {
  type: null,
  complete: false,
};

export const reducerCandidacy = (
  state = initialState,
  action: CandidacyAction
) => {
  switch (action.type) {
    case SET_COMPLETED:
      return { ...state, complete: action.completed };
    default:
      return state;
  }
};
