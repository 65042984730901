import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import {MoveStep} from "graphql/types";

import {DataExtra} from "hooks/use-step-extra";
import {useUpdateCandidacy} from "hooks/use-update-candidacy";

import {useCandidacy} from "state/candidacy";
import {setCompleted} from "state/candidacy/actions/set-completed";

import {FieldCol} from "components/atoms/form/grid";
import {CheckboxGroup} from "components/atoms/form/checkbox-group";
import {RadioGroup} from "components/atoms/form/radio-group";
import {DialogFormError} from "components/molecules/dialog-form-error";

type FormProps = {
  candidacyId ?: string;
  id : string;
  initialValues: DataExtra;
  onRequestEnd : () => void;
  onRequestStart : () => void;
  onValidation : (isValid : boolean) => void;
  proceedOnSuccess ?: boolean;
  readOnly ?: boolean;
  onSuccess?: () => Promise<void> | void;
};

export const FormExtra : FC<FormProps> = ({
  candidacyId,
  id,
  initialValues,
  onRequestEnd,
  onRequestStart,
  onValidation,
  proceedOnSuccess = true,
  readOnly,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const [, dispatch] = useCandidacy();

  const schema = useValidationSchema();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<DataExtra>({
    defaultValues: initialValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(
    () => {
      onValidation(isValid);
    },
    [isValid, onValidation]
  );

  const [operationError, setOperationError] = useState(null);
  const isDialogErrorOpen = operationError != null;

  const { mutate, isLoading } = useUpdateCandidacy(
    candidacyId,
    {
      onError () {
        setOperationError("UNKNOWN");
      },
      /**
       * candidacyId is set only when the component is rendered in backoffice area;
       * in this case we don't want to render the confirmation dialog.
       */
      onSuccess: candidacyId
        ? null
        : async (res) => {
          if (res.error) {
            setOperationError(res.error);
            return;
          } else if (res.nextStep === "NONE") {
            await onSuccess?.();
            dispatch(setCompleted(true));
          } else {
            await onSuccess?.();
          }
        },
      proceedOnSuccess,
    }
  );

  useEffect(
    () => {
      if (isLoading) {
        onRequestStart();
      } else {
        onRequestEnd();
      }
    },
    [isLoading, onRequestStart, onRequestEnd]
  );

  const onSubmit = (payload : DataExtra) => {
    mutate({
      extra: payload.extra || [],
      marketing: payload.marketing || "no",
      move: proceedOnSuccess ? MoveStep.Next : MoveStep.None,
    });
  };

  return (
    <>
      <form className="flex flex-col flex-1" id={id} noValidate onSubmit={handleSubmit(onSubmit)}>
        <FieldCol>
          <CheckboxGroup
            legend={t("step-extra.form.labelExtra")}
            name="extra"
            options={[
              {
                label: t("step-extra.form.extra_shop"),
                value: "shop",
              },
              {
                label: t("step-extra.form.extra_special"),
                value: "special",
              },
              {
                label: t("step-extra.form.extra_sos"),
                value: "sos",
              },
              {
                label: t("step-extra.form.extra_pr"),
                value: "pr",
              },
            ]}
            readOnly={readOnly}
            register={register}
          />
          <RadioGroup
            error={errors.marketing?.message}
            legend={t("step-extra.form.labelMarketing")}
            name="marketing"
            options={[
              {
                label: t("yes"),
                value: "yes",
              },
              {
                label: t("no"),
                value: "no",
              },
            ]}
            readOnly={readOnly}
            register={register}
          />
        </FieldCol>
      </form>
      <DialogFormError
        close={() => { setOperationError(null); }}
        error={operationError}
        isOpen={isDialogErrorOpen}
      />
    </>
  );
};

const useValidationSchema = () => {
  const { t } = useTranslation();
  return yup.object()
    .shape({
      marketing: yup.string()
        .required(t("errorRequired"))
        .oneOf(["no", "yes"], t("errorInvalid")),
    });
};
