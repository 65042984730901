import { FC } from "react";
import { UseFormRegister } from "react-hook-form";

import { Checkbox } from "./checkbox";
import { Error } from "./field-error";

type Option = {
  label : string;
  value : string;
}

type CheckboxGroupProps = {
  error ?: string;
  legend ?: string;
  name : string;
  options : Option[];
  readOnly ?: boolean;
  register : UseFormRegister<any>;
};

export const CheckboxGroup : FC<CheckboxGroupProps> = ({ error, legend, name, options, readOnly, register }) => {
  return (
    <div className="space-y-3" role="group">
      {legend && <Legend>{legend}</Legend>}
      {options.map(
        ({ label, value }) => {
          return <Checkbox key={value} label={label} {...register(name)} readOnly={readOnly} value={value} />;
        }
      )}
      {error && (
        <Error>{error}</Error>
      )}
    </div>
  );
};

const Legend : FC = ({ children }) => {
  return (
    <legend className="text-sm font-semibold">
      {children}
    </legend>
  );
};
