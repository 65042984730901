import {IAnalyticsTracker, SetUserInput} from "./IAnalyticsTracker";
import ReactPixel from "react-facebook-pixel";

export class FacebookPixelTracker implements IAnalyticsTracker {
  candidacyRejected(): void {
  }

  candidacyApproved(): void {
  }

  setUser(data: SetUserInput): void {
  }

  beginCandidacy(): void {
    ReactPixel.trackCustom("BeginCandidacy", "candidacy");
  }

  endCandidacy(): void {
    ReactPixel.trackCustom("EndCandidacy", "candidacy");
  }

  locationStepCompleted(): void {
  }

  homePhotosStepCompleted(): void {
  }

  homeAccessibilityStepCompleted(): void {
  }

  homeInfoStepCompleted(): void {
  }

  cuisinePhotosStepCompleted(): void {
  }

  cuisineInfoStepCompleted(): void {
  }

  proposalStepCompleted(): void {
  }

  profileStepCompleted(): void {
  }

  certificatesStepCompleted(): void {
  }

  extraEarnStepCompleted(): void {
  }
}
