import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MoveStep } from "graphql/types";

import { useRegions } from "hooks/use-region";
import { DataCusineInformation } from "hooks/use-step-cusine-information";
import { useUpdateCandidacy } from "hooks/use-update-candidacy";

import { useCandidacy } from "state/candidacy";
import { setCompleted } from "state/candidacy/actions/set-completed";

import { FieldCol } from "components/atoms/form/grid";
import { Select } from "components/atoms/form/select";
import { SectionTitle } from "components/atoms/form/section-title";
import { Textarea } from "components/atoms/form/textarea";
import { DialogFormError } from "components/molecules/dialog-form-error";

type FormProps = {
  candidacyId ?: string;
  id : string;
  initialValues: DataCusineInformation;
  onRequestEnd : () => void;
  onRequestStart : () => void;
  onValidation : (isValid : boolean) => void;
  proceedOnSuccess ?: boolean;
  readOnly ?: boolean;
  onSuccess?: () => void;
};

export const FormCusineInfo : FC<FormProps> = ({
  candidacyId,
  id,
  initialValues,
  onRequestEnd,
  onRequestStart,
  onValidation,
  proceedOnSuccess = true,
  readOnly,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const [, dispatch] = useCandidacy();

  const regions = useRegions();

  const schema = useValidationSchema();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<DataCusineInformation>({
    defaultValues: initialValues,
    delayError: 1000,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(
    () => {
      onValidation(isValid);
    },
    [isValid, onValidation]
  );

  const [operationError, setOperationError] = useState(null);
  const isDialogErrorOpen = operationError != null;

  const { mutate, isLoading } = useUpdateCandidacy(
    candidacyId,
    {
      onError () {
        setOperationError("UNKNOWN");
      },
      /**
       * candidacyId is set only when the component is rendered in backoffice area;
       * in this case we don't want to render the confirmation dialog.
       */
      onSuccess: candidacyId
        ? null
        : (res) => {
          if (res.error) {
            setOperationError(res.error);
            return;
          } else if (res.nextStep === "NONE") {
            dispatch(setCompleted(true));
          }
          onSuccess?.();
        },
      proceedOnSuccess,
    }
  );

  useEffect(
    () => {
      if (isLoading) {
        onRequestStart();
      } else {
        onRequestEnd();
      }
    },
    [isLoading, onRequestStart, onRequestEnd]
  );

  const onSubmit = (payload : DataCusineInformation) => {
    mutate({ ...payload, move: proceedOnSuccess ? MoveStep.Next : MoveStep.None });
  };

  return (
    <>
      <form id={id} noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-center max-w-[540px] mx-auto">
          <FieldCol>
            <Textarea
              {...register("description")}
              error={errors.description?.message}
              label={t("step-cusine-info.form.labelDescription")}
              placeholder={t("step-cusine-info.form.placeholderDescription")}
              readOnly={readOnly}
            />
            <div>
              <SectionTitle>
                {t("step-cusine-info.formSectionRegion")}
              </SectionTitle>
              <FieldCol>
                <Select
                  {...register("region")}
                  error={errors.region?.message}
                  label={t("step-cusine-info.form.labelRegion")}
                  options={regions}
                  readOnly={readOnly}
                />
              </FieldCol>
            </div>
          </FieldCol>
        </div>
      </form>
      <DialogFormError
        close={() => { setOperationError(null); }}
        error={operationError}
        isOpen={isDialogErrorOpen}
      />
    </>
  );
};

const useValidationSchema = () => {
  const { t } = useTranslation();
  return yup.object()
    .shape({
      description: yup.string()
        .required(t("errorRequired"))
        .max(2048, t("errorMaxLength", { count: 2048 })),
      region: yup.string()
        .required(t("errorRequired")),
    });
};
