import { useTranslation } from "react-i18next";

export const useGender = () => {
  const { t } = useTranslation();
  return [
    {
      label: t("ctaSelect"),
      value: "",
    },
    {
      label: t("gender_d"),
      value: "donna",
    },
    {
      label: t("gender_u"),
      value: "uomo",
    },
    {
      label: t("gender_x"),
      value: "na",
    },
  ];
};
