import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { ClientError, GraphQLClient } from "graphql-request";
import { useQuery } from "react-query";
import { ENDPOINT } from "graphql/endpoint";
import { CandidacyStatus, ChangeRequest, getSdk, Navigation, UserSteps } from "graphql/types";

export type DataCertificates = {
  haccp ?: string;
  haccpRegion ?: string;
  haccpStart ?: string;
  languages ?: string[];
  sommelier ?: string;
  taster1 ?: string;
  taster2 ?: string;
};

type StepCertificates = DataCertificates & {
  calculatedStatus : CandidacyStatus;
  errors ?: ChangeRequest[];
  navigation : Navigation;
};

export const useStepCertificates = (candidacyId ?: string) => {
  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const getDataCertificatesStep = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: window.ENV.REACT_APP_AUTH0_AUDIENCE,
    });

    const client = new GraphQLClient(ENDPOINT);

    const sdk = getSdk(client);

    const variables = {
      step: UserSteps.Certificates,
    };

    const headers : Record<string, string> = {
      authorization: `Bearer ${accessToken}`,
    };

    if (candidacyId) {
      headers.candidacyId = candidacyId;
    }

    try {
      const data = await sdk.GetCertificates({ data: variables }, headers);

      return data.getCandidacy;
    } catch (error) {
      navigate("/");

      return null;
    }
  };

  return useQuery<StepCertificates, ClientError>("certificates", getDataCertificatesStep, { cacheTime: 0 });
};
