export const SET_COMPLETED = "SET_COMPLETED";

export type ActionSetCompleted = {
  type : "SET_COMPLETED";
  completed : boolean;
};

export const setCompleted = (completed : boolean) : ActionSetCompleted => {
  return { type: SET_COMPLETED, completed };
};
