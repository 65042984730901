import {IAnalyticsTracker, SetUserInput} from "./IAnalyticsTracker";
import LinkedInTag from "react-linkedin-insight";


export class LinkedinInsightTracker implements IAnalyticsTracker {
  candidacyRejected(): void {
  }

  candidacyApproved(): void {
  }

  setUser(data: SetUserInput): void {
  }

  beginCandidacy(): void {
    LinkedInTag.track("14921066");
  }

  endCandidacy(): void {
    LinkedInTag.track("14921074");
  }

  locationStepCompleted(): void {
  }

  homePhotosStepCompleted(): void {
  }

  homeAccessibilityStepCompleted(): void {
  }

  homeInfoStepCompleted(): void {
  }

  cuisinePhotosStepCompleted(): void {
  }

  cuisineInfoStepCompleted(): void {
  }

  proposalStepCompleted(): void {
  }

  profileStepCompleted(): void {
  }

  certificatesStepCompleted(): void {
  }

  extraEarnStepCompleted(): void {
  }
}
