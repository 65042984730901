import { useTranslation } from "react-i18next";
import { optionsRegion } from "utils/geo/regions";

export const useRegions = () => {
  const { t } = useTranslation();
  return [
    {
      label: t("ctaSelect"),
      value: "",
    },
    ...optionsRegion,
  ];
};
