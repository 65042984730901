import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { ClientError, GraphQLClient } from "graphql-request";
import { useQuery } from "react-query";
import { ENDPOINT } from "graphql/endpoint";
import {
  CandidacyStatus,
  ChangeRequest,
  getSdk,
  Navigation,
  UserSteps,
} from "graphql/types";

export type DataHomeAccessibility = {
  barriers?: string;
  elevator?: string;
  floor?: string;
  pets?: string;
  seats?: string;
  seatsOpen?: string;
};

type StepHomeAccessibility = DataHomeAccessibility & {
  calculatedStatus: CandidacyStatus;
  errors?: ChangeRequest[];
  navigation: Navigation;
  candidacyType?: string | null;
};

export const useStepHomeAccessibility = (candidacyId?: string) => {
  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const getDataHomeAccessibilityStep = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: window.ENV.REACT_APP_AUTH0_AUDIENCE,
    });

    const client = new GraphQLClient(ENDPOINT);

    const sdk = getSdk(client);

    const variables = {
      step: UserSteps.Accessibility,
    };

    const headers: Record<string, string> = {
      authorization: `Bearer ${accessToken}`,
    };

    if (candidacyId) {
      headers.candidacyId = candidacyId;
    }

    try {
      const data = await sdk.GetHomeAccessibility({ data: variables }, headers);

      return data.getCandidacy;
    } catch (error) {
      navigate("/");

      return null;
    }
  };

  return useQuery<StepHomeAccessibility, ClientError>(
    "home-accessibility",
    getDataHomeAccessibilityStep,
    { cacheTime: 0 }
  );
};
