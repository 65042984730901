import { FC } from "react";

type IconCrossProps = {
  className ?: string;
};

export const IconCross : FC<IconCrossProps> = ({ className }) => {
  return (
    <svg className={className} aria-hidden="true" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em">
      <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144m224 0L144 368" />
    </svg>
  );
};
