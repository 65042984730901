/* eslint-disable */
import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type AcceptCandidacyOutput = {
  __typename?: 'AcceptCandidacyOutput';
  error?: Maybe<Scalars['String']>;
  status: OutputStatus;
};

export enum AscDesc {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type CandidaciesConnection = {
  __typename?: 'CandidaciesConnection';
  edges: Array<CandidacyEdge>;
  pageInfo: PageInfo;
};

export type CandidacyEdge = {
  __typename?: 'CandidacyEdge';
  cursor: Scalars['String'];
  node: EdgeCandidacyOutput;
};

export enum CandidacyStatus {
  Accepted = 'ACCEPTED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Ongoing = 'ONGOING',
  Rejected = 'REJECTED',
  Rejectedforinactivity = 'REJECTEDFORINACTIVITY',
  Withchangerequests = 'WITHCHANGEREQUESTS'
}

export type ChangeRequest = {
  __typename?: 'ChangeRequest';
  message: Scalars['String'];
  step: UserSteps;
};

export type EdgeCandidacyOutput = {
  __typename?: 'EdgeCandidacyOutput';
  addressRegion?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  barriers?: Maybe<Scalars['String']>;
  calculatedProgress: Scalars['Float'];
  calculatedStatus: CandidacyStatus;
  candidacyType?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  civic?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  creationDate: Scalars['DateTime'];
  cusinePhotos?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discoveredFrom?: Maybe<Scalars['String']>;
  elevator?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  errors?: Maybe<Array<ChangeRequest>>;
  experiences?: Maybe<Array<Scalars['String']>>;
  extra?: Maybe<Array<Scalars['String']>>;
  features?: Maybe<Array<Scalars['String']>>;
  floor?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  haccp?: Maybe<Scalars['String']>;
  haccpRegion?: Maybe<Scalars['String']>;
  haccpStart?: Maybe<Scalars['String']>;
  homePhotos?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  kind?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Scalars['String']>>;
  lastModifiedDate?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  marketing?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  otherDescription?: Maybe<Scalars['String']>;
  pets?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  privacy?: Maybe<Scalars['String']>;
  proposals?: Maybe<Array<Scalars['String']>>;
  province?: Maybe<Scalars['String']>;
  provinceLong?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  seats?: Maybe<Scalars['String']>;
  seatsOpen?: Maybe<Scalars['String']>;
  sommelier?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  taster1?: Maybe<Scalars['String']>;
  taster2?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type GetCandidacyInput = {
  step?: InputMaybe<UserSteps>;
};

export type GetCandidacyOutput = {
  __typename?: 'GetCandidacyOutput';
  addressRegion?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  barriers?: Maybe<Scalars['String']>;
  calculatedStatus: CandidacyStatus;
  candidacyType?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  civic?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  creationDate: Scalars['DateTime'];
  cusinePhotos?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discoveredFrom?: Maybe<Scalars['String']>;
  elevator?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  errors?: Maybe<Array<ChangeRequest>>;
  experiences?: Maybe<Array<Scalars['String']>>;
  extra?: Maybe<Array<Scalars['String']>>;
  features?: Maybe<Array<Scalars['String']>>;
  floor?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  haccp?: Maybe<Scalars['String']>;
  haccpRegion?: Maybe<Scalars['String']>;
  haccpStart?: Maybe<Scalars['String']>;
  homePhotos?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  kind?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Scalars['String']>>;
  lastModifiedDate?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  marketing?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  navigation: Navigation;
  otherDescription?: Maybe<Scalars['String']>;
  pets?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  privacy?: Maybe<Scalars['String']>;
  proposals?: Maybe<Array<Scalars['String']>>;
  province?: Maybe<Scalars['String']>;
  provinceLong?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  seats?: Maybe<Scalars['String']>;
  seatsOpen?: Maybe<Scalars['String']>;
  sommelier?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  taster1?: Maybe<Scalars['String']>;
  taster2?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type GetCurrentStepOutput = {
  __typename?: 'GetCurrentStepOutput';
  candidacyType?: Maybe<Scalars['String']>;
  currentStep: UserSteps;
};

export type ListCandidaciesInput = {
  after?: InputMaybe<Scalars['String']>;
  ascdesc?: InputMaybe<AscDesc>;
  before?: InputMaybe<Scalars['String']>;
  candidacyType?: InputMaybe<Scalars['String']>;
  createdFrom?: InputMaybe<Scalars['String']>;
  createdTo?: InputMaybe<Scalars['String']>;
  editedFrom?: InputMaybe<Scalars['String']>;
  editedTo?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  fullText?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<OrderBy>;
  statuses?: InputMaybe<Array<CandidacyStatus>>;
};

export enum MoveStep {
  Next = 'NEXT',
  None = 'NONE',
  Previous = 'PREVIOUS'
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptCandidacy: AcceptCandidacyOutput;
  rejectCandidacy: RejectCandidacyOutput;
  requestChanges: RequestChangesOutput;
  updateCandidacy: UpdateCandidacyOutput;
};


export type MutationRejectCandidacyArgs = {
  data: RejectCandidacyInput;
};


export type MutationRequestChangesArgs = {
  data: RequestChangesInput;
};


export type MutationUpdateCandidacyArgs = {
  data: UpdateCandidacyInput;
};

export type Navigation = {
  __typename?: 'Navigation';
  actualStepNumber: Scalars['Float'];
  nextStep: NextStep;
  previousStep: PreviousStep;
  totalStepsNumber: Scalars['Float'];
};

export enum NextStatus {
  Complete = 'COMPLETE',
  Continue = 'CONTINUE',
  NextError = 'NEXT_ERROR',
  NextReadOnly = 'NEXT_READ_ONLY',
  None = 'NONE',
  Save = 'SAVE'
}

export type NextStep = {
  __typename?: 'NextStep';
  status: NextStatus;
  step: UserSteps;
};

export enum OrderBy {
  Createdate = 'CREATEDATE',
  Modifydate = 'MODIFYDATE',
  Status = 'STATUS',
  Text = 'TEXT'
}

export enum OutputStatus {
  Error = 'ERROR',
  Ok = 'OK'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
};

export type PongOutput = {
  __typename?: 'PongOutput';
  status: Scalars['String'];
};

export enum PreviousStatus {
  Back = 'BACK',
  BackReadOnly = 'BACK_READ_ONLY',
  None = 'NONE',
  PreviousError = 'PREVIOUS_ERROR'
}

export type PreviousStep = {
  __typename?: 'PreviousStep';
  status: PreviousStatus;
  step: UserSteps;
};

export type Query = {
  __typename?: 'Query';
  authPing: Scalars['String'];
  getCandidacy: GetCandidacyOutput;
  getCandidacyEdge: CandidacyEdge;
  getCurrentStep: GetCurrentStepOutput;
  graphPing: PongOutput;
  listCandidacies: CandidaciesConnection;
  ping: Scalars['String'];
};


export type QueryGetCandidacyArgs = {
  data: GetCandidacyInput;
};


export type QueryListCandidaciesArgs = {
  data: ListCandidaciesInput;
};

export type RejectCandidacyInput = {
  rejectionType: RejectionType;
};

export type RejectCandidacyOutput = {
  __typename?: 'RejectCandidacyOutput';
  error?: Maybe<Scalars['String']>;
  status: OutputStatus;
};

export enum RejectionType {
  Inactivity = 'INACTIVITY',
  Normal = 'NORMAL'
}

export type RequestChangeInput = {
  message?: InputMaybe<Scalars['String']>;
  step: UserSteps;
};

export type RequestChangesInput = {
  requests: Array<RequestChangeInput>;
};

export type RequestChangesOutput = {
  __typename?: 'RequestChangesOutput';
  error?: Maybe<Scalars['String']>;
  status: OutputStatus;
};

export type UpdateCandidacyInput = {
  addressRegion?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<Scalars['String']>;
  barriers?: InputMaybe<Scalars['String']>;
  candidacyType?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  civic?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  cusinePhotos?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discoveredFrom?: InputMaybe<Scalars['String']>;
  elevator?: InputMaybe<Scalars['String']>;
  experiences?: InputMaybe<Array<Scalars['String']>>;
  extra?: InputMaybe<Array<Scalars['String']>>;
  features?: InputMaybe<Array<Scalars['String']>>;
  floor?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  haccp?: InputMaybe<Scalars['String']>;
  haccpRegion?: InputMaybe<Scalars['String']>;
  haccpStart?: InputMaybe<Scalars['String']>;
  homePhotos?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  languages?: InputMaybe<Array<Scalars['String']>>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  marketing?: InputMaybe<Scalars['String']>;
  move: MoveStep;
  name?: InputMaybe<Scalars['String']>;
  otherDescription?: InputMaybe<Scalars['String']>;
  pets?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  privacy?: InputMaybe<Scalars['String']>;
  proposals?: InputMaybe<Array<Scalars['String']>>;
  province?: InputMaybe<Scalars['String']>;
  provinceLong?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  seats?: InputMaybe<Scalars['String']>;
  seatsOpen?: InputMaybe<Scalars['String']>;
  sommelier?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  taster1?: InputMaybe<Scalars['String']>;
  taster2?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type UpdateCandidacyOutput = {
  __typename?: 'UpdateCandidacyOutput';
  error?: Maybe<Scalars['String']>;
  nextStep?: Maybe<UserSteps>;
  previousStep?: Maybe<UserSteps>;
  status: OutputStatus;
};

export enum UserSteps {
  Accessibility = 'ACCESSIBILITY',
  Certificates = 'CERTIFICATES',
  Cusine = 'CUSINE',
  Cusineinfo = 'CUSINEINFO',
  Extra = 'EXTRA',
  Home = 'HOME',
  Homeinfo = 'HOMEINFO',
  Location = 'LOCATION',
  None = 'NONE',
  Profile = 'PROFILE',
  Proposals = 'PROPOSALS'
}

export type NavigationFragment = { __typename?: 'GetCandidacyOutput', navigation: { __typename?: 'Navigation', actualStepNumber: number, totalStepsNumber: number, nextStep: { __typename?: 'NextStep', status: NextStatus, step: UserSteps }, previousStep: { __typename?: 'PreviousStep', status: PreviousStatus, step: UserSteps } } };

export type ApproveCandidacyMutationVariables = Exact<{ [key: string]: never; }>;


export type ApproveCandidacyMutation = { __typename?: 'Mutation', acceptCandidacy: { __typename?: 'AcceptCandidacyOutput', error?: string | null, status: OutputStatus } };

export type RejectCandidacyMutationVariables = Exact<{
  data: RejectCandidacyInput;
}>;


export type RejectCandidacyMutation = { __typename?: 'Mutation', rejectCandidacy: { __typename?: 'RejectCandidacyOutput', error?: string | null, status: OutputStatus } };

export type SendFeedbackMutationVariables = Exact<{
  data: RequestChangesInput;
}>;


export type SendFeedbackMutation = { __typename?: 'Mutation', requestChanges: { __typename?: 'RequestChangesOutput', error?: string | null, status: OutputStatus } };

export type UpdateCandidacyMutationVariables = Exact<{
  data: UpdateCandidacyInput;
}>;


export type UpdateCandidacyMutation = { __typename?: 'Mutation', updateCandidacy: { __typename?: 'UpdateCandidacyOutput', error?: string | null, nextStep?: UserSteps | null, previousStep?: UserSteps | null, status: OutputStatus } };

export type GetCandidacyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCandidacyQuery = { __typename?: 'Query', getCandidacyEdge: { __typename?: 'CandidacyEdge', node: { __typename?: 'EdgeCandidacyOutput', id: string, state: string, calculatedProgress: number, calculatedStatus: CandidacyStatus, candidacyType?: string | null, creationDate: any, lastModifiedDate?: any | null, city?: string | null, avatar?: string | null, email: string, name?: string | null, surname?: string | null, errors?: Array<{ __typename?: 'ChangeRequest', message: string, step: UserSteps }> | null } } };

export type GetCertificatesQueryVariables = Exact<{
  data: GetCandidacyInput;
}>;


export type GetCertificatesQuery = { __typename?: 'Query', getCandidacy: { __typename?: 'GetCandidacyOutput', calculatedStatus: CandidacyStatus, haccp?: string | null, haccpRegion?: string | null, haccpStart?: string | null, languages?: Array<string> | null, sommelier?: string | null, taster1?: string | null, taster2?: string | null, errors?: Array<{ __typename?: 'ChangeRequest', message: string, step: UserSteps }> | null, navigation: { __typename?: 'Navigation', actualStepNumber: number, totalStepsNumber: number, nextStep: { __typename?: 'NextStep', status: NextStatus, step: UserSteps }, previousStep: { __typename?: 'PreviousStep', status: PreviousStatus, step: UserSteps } } } };

export type GetCurrentStepQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentStepQuery = { __typename?: 'Query', getCurrentStep: { __typename?: 'GetCurrentStepOutput', currentStep: UserSteps, candidacyType?: string | null } };

export type GetCusineInformationQueryVariables = Exact<{
  data: GetCandidacyInput;
}>;


export type GetCusineInformationQuery = { __typename?: 'Query', getCandidacy: { __typename?: 'GetCandidacyOutput', calculatedStatus: CandidacyStatus, description?: string | null, region?: string | null, errors?: Array<{ __typename?: 'ChangeRequest', message: string, step: UserSteps }> | null, navigation: { __typename?: 'Navigation', actualStepNumber: number, totalStepsNumber: number, nextStep: { __typename?: 'NextStep', status: NextStatus, step: UserSteps }, previousStep: { __typename?: 'PreviousStep', status: PreviousStatus, step: UserSteps } } } };

export type GetCusinePhotosQueryVariables = Exact<{
  data: GetCandidacyInput;
}>;


export type GetCusinePhotosQuery = { __typename?: 'Query', getCandidacy: { __typename?: 'GetCandidacyOutput', calculatedStatus: CandidacyStatus, cusinePhotos?: string | null, errors?: Array<{ __typename?: 'ChangeRequest', message: string, step: UserSteps }> | null, navigation: { __typename?: 'Navigation', actualStepNumber: number, totalStepsNumber: number, nextStep: { __typename?: 'NextStep', status: NextStatus, step: UserSteps }, previousStep: { __typename?: 'PreviousStep', status: PreviousStatus, step: UserSteps } } } };

export type GetExperienceAndProposalsQueryVariables = Exact<{
  data: GetCandidacyInput;
}>;


export type GetExperienceAndProposalsQuery = { __typename?: 'Query', getCandidacy: { __typename?: 'GetCandidacyOutput', calculatedStatus: CandidacyStatus, experiences?: Array<string> | null, proposals?: Array<string> | null, errors?: Array<{ __typename?: 'ChangeRequest', message: string, step: UserSteps }> | null, navigation: { __typename?: 'Navigation', actualStepNumber: number, totalStepsNumber: number, nextStep: { __typename?: 'NextStep', status: NextStatus, step: UserSteps }, previousStep: { __typename?: 'PreviousStep', status: PreviousStatus, step: UserSteps } } } };

export type GetExtraQueryVariables = Exact<{
  data: GetCandidacyInput;
}>;


export type GetExtraQuery = { __typename?: 'Query', getCandidacy: { __typename?: 'GetCandidacyOutput', calculatedStatus: CandidacyStatus, extra?: Array<string> | null, marketing?: string | null, errors?: Array<{ __typename?: 'ChangeRequest', message: string, step: UserSteps }> | null, navigation: { __typename?: 'Navigation', actualStepNumber: number, totalStepsNumber: number, nextStep: { __typename?: 'NextStep', status: NextStatus, step: UserSteps }, previousStep: { __typename?: 'PreviousStep', status: PreviousStatus, step: UserSteps } } } };

export type GetHomeAccessibilityQueryVariables = Exact<{
  data: GetCandidacyInput;
}>;


export type GetHomeAccessibilityQuery = { __typename?: 'Query', getCandidacy: { __typename?: 'GetCandidacyOutput', barriers?: string | null, calculatedStatus: CandidacyStatus, candidacyType?: string | null, elevator?: string | null, floor?: string | null, pets?: string | null, seats?: string | null, seatsOpen?: string | null, errors?: Array<{ __typename?: 'ChangeRequest', message: string, step: UserSteps }> | null, navigation: { __typename?: 'Navigation', actualStepNumber: number, totalStepsNumber: number, nextStep: { __typename?: 'NextStep', status: NextStatus, step: UserSteps }, previousStep: { __typename?: 'PreviousStep', status: PreviousStatus, step: UserSteps } } } };

export type GetHomeInformationQueryVariables = Exact<{
  data: GetCandidacyInput;
}>;


export type GetHomeInformationQuery = { __typename?: 'Query', getCandidacy: { __typename?: 'GetCandidacyOutput', calculatedStatus: CandidacyStatus, candidacyType?: string | null, features?: Array<string> | null, kind?: string | null, errors?: Array<{ __typename?: 'ChangeRequest', message: string, step: UserSteps }> | null, navigation: { __typename?: 'Navigation', actualStepNumber: number, totalStepsNumber: number, nextStep: { __typename?: 'NextStep', status: NextStatus, step: UserSteps }, previousStep: { __typename?: 'PreviousStep', status: PreviousStatus, step: UserSteps } } } };

export type GetHomePhotosQueryVariables = Exact<{
  data: GetCandidacyInput;
}>;


export type GetHomePhotosQuery = { __typename?: 'Query', getCandidacy: { __typename?: 'GetCandidacyOutput', calculatedStatus: CandidacyStatus, candidacyType?: string | null, homePhotos?: string | null, errors?: Array<{ __typename?: 'ChangeRequest', message: string, step: UserSteps }> | null, navigation: { __typename?: 'Navigation', actualStepNumber: number, totalStepsNumber: number, nextStep: { __typename?: 'NextStep', status: NextStatus, step: UserSteps }, previousStep: { __typename?: 'PreviousStep', status: PreviousStatus, step: UserSteps } } } };

export type GetLocationQueryVariables = Exact<{
  data: GetCandidacyInput;
}>;


export type GetLocationQuery = { __typename?: 'Query', getCandidacy: { __typename?: 'GetCandidacyOutput', addressRegion?: string | null, calculatedStatus: CandidacyStatus, candidacyType?: string | null, city?: string | null, civic?: string | null, country?: string | null, discoveredFrom?: string | null, latitude?: number | null, longitude?: number | null, name?: string | null, otherDescription?: string | null, phone?: string | null, privacy?: string | null, province?: string | null, provinceLong?: string | null, street?: string | null, zip?: string | null, errors?: Array<{ __typename?: 'ChangeRequest', message: string, step: UserSteps }> | null, navigation: { __typename?: 'Navigation', actualStepNumber: number, totalStepsNumber: number, nextStep: { __typename?: 'NextStep', status: NextStatus, step: UserSteps }, previousStep: { __typename?: 'PreviousStep', status: PreviousStatus, step: UserSteps } } } };

export type GetProfileQueryVariables = Exact<{
  data: GetCandidacyInput;
}>;


export type GetProfileQuery = { __typename?: 'Query', getCandidacy: { __typename?: 'GetCandidacyOutput', avatar?: string | null, calculatedStatus: CandidacyStatus, candidacyType?: string | null, gender?: string | null, name?: string | null, surname?: string | null, errors?: Array<{ __typename?: 'ChangeRequest', message: string, step: UserSteps }> | null, navigation: { __typename?: 'Navigation', actualStepNumber: number, totalStepsNumber: number, nextStep: { __typename?: 'NextStep', status: NextStatus, step: UserSteps }, previousStep: { __typename?: 'PreviousStep', status: PreviousStatus, step: UserSteps } } } };

export type GetCandidaciesQueryVariables = Exact<{
  data: ListCandidaciesInput;
}>;


export type GetCandidaciesQuery = { __typename?: 'Query', listCandidacies: { __typename?: 'CandidaciesConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor: string, endCursor: string }, edges: Array<{ __typename?: 'CandidacyEdge', cursor: string, node: { __typename?: 'EdgeCandidacyOutput', id: string, state: string, calculatedProgress: number, calculatedStatus: CandidacyStatus, candidacyType?: string | null, creationDate: any, lastModifiedDate?: any | null, city?: string | null, avatar?: string | null, email: string, name?: string | null, surname?: string | null, errors?: Array<{ __typename?: 'ChangeRequest', message: string, step: UserSteps }> | null } }> } };

export const NavigationFragmentDoc = gql`
    fragment Navigation on GetCandidacyOutput {
  navigation {
    nextStep {
      status
      step
    }
    previousStep {
      status
      step
    }
    actualStepNumber
    totalStepsNumber
  }
}
    `;
export const ApproveCandidacyDocument = gql`
    mutation ApproveCandidacy {
  acceptCandidacy {
    error
    status
  }
}
    `;
export const RejectCandidacyDocument = gql`
    mutation RejectCandidacy($data: RejectCandidacyInput!) {
  rejectCandidacy(data: $data) {
    error
    status
  }
}
    `;
export const SendFeedbackDocument = gql`
    mutation SendFeedback($data: RequestChangesInput!) {
  requestChanges(data: $data) {
    error
    status
  }
}
    `;
export const UpdateCandidacyDocument = gql`
    mutation UpdateCandidacy($data: UpdateCandidacyInput!) {
  updateCandidacy(data: $data) {
    error
    nextStep
    previousStep
    status
  }
}
    `;
export const GetCandidacyDocument = gql`
    query GetCandidacy {
  getCandidacyEdge {
    node {
      id
      state
      calculatedProgress
      calculatedStatus
      candidacyType
      creationDate
      lastModifiedDate
      city
      avatar
      email
      name
      surname
      errors {
        message
        step
      }
    }
  }
}
    `;
export const GetCertificatesDocument = gql`
    query GetCertificates($data: GetCandidacyInput!) {
  getCandidacy(data: $data) {
    calculatedStatus
    haccp
    haccpRegion
    haccpStart
    languages
    sommelier
    taster1
    taster2
    errors {
      message
      step
    }
    ...Navigation
  }
}
    ${NavigationFragmentDoc}`;
export const GetCurrentStepDocument = gql`
    query GetCurrentStep {
  getCurrentStep {
    currentStep
    candidacyType
  }
}
    `;
export const GetCusineInformationDocument = gql`
    query GetCusineInformation($data: GetCandidacyInput!) {
  getCandidacy(data: $data) {
    calculatedStatus
    description
    region
    errors {
      message
      step
    }
    ...Navigation
  }
}
    ${NavigationFragmentDoc}`;
export const GetCusinePhotosDocument = gql`
    query GetCusinePhotos($data: GetCandidacyInput!) {
  getCandidacy(data: $data) {
    calculatedStatus
    cusinePhotos
    errors {
      message
      step
    }
    ...Navigation
  }
}
    ${NavigationFragmentDoc}`;
export const GetExperienceAndProposalsDocument = gql`
    query GetExperienceAndProposals($data: GetCandidacyInput!) {
  getCandidacy(data: $data) {
    calculatedStatus
    experiences
    proposals
    errors {
      message
      step
    }
    ...Navigation
  }
}
    ${NavigationFragmentDoc}`;
export const GetExtraDocument = gql`
    query GetExtra($data: GetCandidacyInput!) {
  getCandidacy(data: $data) {
    calculatedStatus
    extra
    marketing
    errors {
      message
      step
    }
    ...Navigation
  }
}
    ${NavigationFragmentDoc}`;
export const GetHomeAccessibilityDocument = gql`
    query GetHomeAccessibility($data: GetCandidacyInput!) {
  getCandidacy(data: $data) {
    barriers
    calculatedStatus
    candidacyType
    elevator
    floor
    pets
    seats
    seatsOpen
    errors {
      message
      step
    }
    ...Navigation
  }
}
    ${NavigationFragmentDoc}`;
export const GetHomeInformationDocument = gql`
    query GetHomeInformation($data: GetCandidacyInput!) {
  getCandidacy(data: $data) {
    calculatedStatus
    candidacyType
    features
    kind
    errors {
      message
      step
    }
    ...Navigation
  }
}
    ${NavigationFragmentDoc}`;
export const GetHomePhotosDocument = gql`
    query GetHomePhotos($data: GetCandidacyInput!) {
  getCandidacy(data: $data) {
    calculatedStatus
    candidacyType
    homePhotos
    errors {
      message
      step
    }
    ...Navigation
  }
}
    ${NavigationFragmentDoc}`;
export const GetLocationDocument = gql`
    query GetLocation($data: GetCandidacyInput!) {
  getCandidacy(data: $data) {
    addressRegion
    calculatedStatus
    candidacyType
    city
    civic
    country
    discoveredFrom
    latitude
    longitude
    name
    otherDescription
    phone
    privacy
    province
    provinceLong
    street
    zip
    errors {
      message
      step
    }
    ...Navigation
  }
}
    ${NavigationFragmentDoc}`;
export const GetProfileDocument = gql`
    query GetProfile($data: GetCandidacyInput!) {
  getCandidacy(data: $data) {
    avatar
    calculatedStatus
    candidacyType
    gender
    name
    surname
    errors {
      message
      step
    }
    ...Navigation
  }
}
    ${NavigationFragmentDoc}`;
export const GetCandidaciesDocument = gql`
    query GetCandidacies($data: ListCandidaciesInput!) {
  listCandidacies(data: $data) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        state
        calculatedProgress
        calculatedStatus
        candidacyType
        creationDate
        lastModifiedDate
        city
        avatar
        email
        name
        surname
        errors {
          message
          step
        }
      }
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    ApproveCandidacy(variables?: ApproveCandidacyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ApproveCandidacyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ApproveCandidacyMutation>(ApproveCandidacyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ApproveCandidacy');
    },
    RejectCandidacy(variables: RejectCandidacyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RejectCandidacyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RejectCandidacyMutation>(RejectCandidacyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RejectCandidacy');
    },
    SendFeedback(variables: SendFeedbackMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SendFeedbackMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SendFeedbackMutation>(SendFeedbackDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SendFeedback');
    },
    UpdateCandidacy(variables: UpdateCandidacyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCandidacyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCandidacyMutation>(UpdateCandidacyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateCandidacy');
    },
    GetCandidacy(variables?: GetCandidacyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCandidacyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCandidacyQuery>(GetCandidacyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCandidacy');
    },
    GetCertificates(variables: GetCertificatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCertificatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCertificatesQuery>(GetCertificatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCertificates');
    },
    GetCurrentStep(variables?: GetCurrentStepQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCurrentStepQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCurrentStepQuery>(GetCurrentStepDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCurrentStep');
    },
    GetCusineInformation(variables: GetCusineInformationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCusineInformationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCusineInformationQuery>(GetCusineInformationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCusineInformation');
    },
    GetCusinePhotos(variables: GetCusinePhotosQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCusinePhotosQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCusinePhotosQuery>(GetCusinePhotosDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCusinePhotos');
    },
    GetExperienceAndProposals(variables: GetExperienceAndProposalsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetExperienceAndProposalsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetExperienceAndProposalsQuery>(GetExperienceAndProposalsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetExperienceAndProposals');
    },
    GetExtra(variables: GetExtraQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetExtraQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetExtraQuery>(GetExtraDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetExtra');
    },
    GetHomeAccessibility(variables: GetHomeAccessibilityQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetHomeAccessibilityQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetHomeAccessibilityQuery>(GetHomeAccessibilityDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetHomeAccessibility');
    },
    GetHomeInformation(variables: GetHomeInformationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetHomeInformationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetHomeInformationQuery>(GetHomeInformationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetHomeInformation');
    },
    GetHomePhotos(variables: GetHomePhotosQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetHomePhotosQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetHomePhotosQuery>(GetHomePhotosDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetHomePhotos');
    },
    GetLocation(variables: GetLocationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetLocationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetLocationQuery>(GetLocationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetLocation');
    },
    GetProfile(variables: GetProfileQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetProfileQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetProfileQuery>(GetProfileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetProfile');
    },
    GetCandidacies(variables: GetCandidaciesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCandidaciesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCandidaciesQuery>(GetCandidaciesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCandidacies');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;