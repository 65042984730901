import { FC } from "react";
import { useTranslation } from "react-i18next";
import cssClass from "classnames";

type FeedbackProps = {
  className ?: string;
};

export const Feedback : FC<FeedbackProps> = ({ children, className }) => {
  const { t } = useTranslation();
  return (
    <div role="alert" className={cssClass(className, "py-1 pl-2 ml-1 text-sm font-semibold text-red-800 border-l-4 border-red-800")}>
      <b>{t("feedback")}:</b>
      <br/>
      <span className="text-gray-800 whitespace-pre-line">{children}</span>
    </div>
  );
};
