import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, DialogProps } from "components/atoms/dialog";

type DialogFormErrorProps = Pick<DialogProps, "close" | "isOpen"> & {
  error : string | null;
};

export const DialogFormError : FC<DialogFormErrorProps> = ({ error, ...props }) => {
  const { t } = useTranslation();
  const getError = () => {
    switch (error) {
      case "INCOMPLETE":
        return t("submitIncompleteErrorDescription");
      default:
        return t("submitErrorDescription");
    }
  };

  return (
    <Dialog {...props} size="max-w-lg" title={t("submitError")}>
      <div className="space-y-10">
        <div>
          <p className="whitespace-pre-line">{getError()}</p>
        </div>
        <div className="flex justify-end space-x-5 text-base">
          <button onClick={props.close} className="px-5 py-2 text-white rounded bg-brandYellow">
            {t("ctaCloseDialog")}
          </button>
        </div>
      </div>
    </Dialog>
  );
};
