import { FC, useCallback, useRef, useState } from "react";
import { nanoid } from "nanoid";
import useBool from "@bscop/use-bool";

import { CandidacyStatus } from "graphql/types";

import { useStepProfile } from "hooks/use-step-profile";

import { IconSpinner } from "components/atoms/icons";
import { FormProfile } from "components/organisms/form-profile";

import { Layout } from "./atoms/layout";
import { AnalyticsTrackerComposite } from "../../../analytics/AnalyticsTrackerComposite";
import { CandidacyType } from "chunks/candidatura";

export const StepProfile: FC = () => {
  const { data, isLoading } = useStepProfile();

  const [requestPending, onRequestStart, onRequestEnd] = useBool(false);

  const [disabled, setDisabled] = useState(true);
  const onValidation = useCallback((isValid) => setDisabled(!isValid), []);

  const refFormId = useRef("_" + nanoid());

  if (isLoading) {
    return (
      <Layout disabled isLoading>
        <div className="flex justify-center mt-10">
          <IconSpinner />
        </div>
      </Layout>
    );
  }

  const {
    errors,
    navigation,
    calculatedStatus,
    candidacyType,
    ...initialValues
  } = data;

  const readOnly =
    calculatedStatus === CandidacyStatus.Completed ||
    calculatedStatus === CandidacyStatus.Accepted;

  return (
    <Layout
      calculatedStatus={calculatedStatus}
      disabled={disabled}
      errors={errors}
      formId={refFormId.current}
      navigation={navigation}
      readOnly={readOnly}
      requestPending={requestPending}
    >
      <FormProfile
        candidacyType={candidacyType as CandidacyType}
        id={refFormId.current}
        initialValues={initialValues}
        onRequestEnd={onRequestEnd}
        onRequestStart={onRequestStart}
        onValidation={onValidation}
        proceedOnSuccess={navigation.nextStep.status !== "COMPLETE"}
        readOnly={readOnly}
        onSuccess={() =>
          AnalyticsTrackerComposite.instance().profileStepCompleted()
        }
      />
    </Layout>
  );
};
