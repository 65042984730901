import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { AppState, Auth0Provider } from "@auth0/auth0-react";

export const AuthenticationProvider: FC = ({ children }) => {
  const navigate = useNavigate();
  return (
    <Auth0Provider
      audience={window.ENV.REACT_APP_AUTH0_AUDIENCE || ""}
      cacheLocation="localstorage"
      clientId={window.ENV.REACT_APP_AUTH0_CLIENT_ID || ""}
      domain={window.ENV.REACT_APP_AUTH0_DOMAIN || ""}
      onRedirectCallback={
        (appState: AppState) => {
          navigate(appState?.returnTo || window.location.pathname);
        }
      }
      redirectUri={`${window.location.origin}`}
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};
