import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ChangeRequest } from "graphql/types";

import { Page, PageProps } from "components/organisms/layout-candidatura";
import {
  TitleSection,
  PageTitle,
} from "components/organisms/layout-candidatura/title";
import { Feedback } from "components/atoms/feedback";
import { ReadonlyWarning } from "components/atoms/warning";
import { useCandidacyCompanyFlow } from "state/candidacy";
import { addCompanySubstringToKey } from "utils/steps";

type LayoutProps = Omit<PageProps, "HighlightedText"> & {
  isReturningCandidate?: boolean;
  name?: string;
  errors?: ChangeRequest[];
  readOnly?: boolean;
};

export const Layout: FC<LayoutProps> = ({
  children,
  errors,
  isReturningCandidate,
  name,
  readOnly,
  ...props
}) => {
  const { t } = useTranslation();
  const isCompanyFlow = useCandidacyCompanyFlow();

  const descriptionKey = isCompanyFlow
    ? "step-location.description_company"
    : "step-location.description";
  return (
    <Page
      {...props}
      HighlightedText={
        <div className="flex flex-col py-6 pl-2 space-y-3 text-white rounded-r-sm bg-tblack">
          <span className="text-6xl">
            {isReturningCandidate
              ? name
                ? t("step-location.panelTitleProfile", { name })
                : t("step-location.panelTitle")
              : t(
                  addCompanySubstringToKey(
                    "step-location.panelTitleFirst",
                    isCompanyFlow
                  )
                )}
          </span>
          <span className="text-4xl">
            {isReturningCandidate
              ? t("step-location.panelSubtitleReturn")
              : t(
                  addCompanySubstringToKey(
                    "step-location.panelSubtitle",
                    isCompanyFlow
                  )
                )}
          </span>
        </div>
      }
    >
      <TitleSection>
        <PageTitle>{t("step-location.title")}</PageTitle>
        <p>
          <Trans i18nKey={descriptionKey}>
            placeholder_1
            <u>placeholder_2</u>
          </Trans>
        </p>
      </TitleSection>
      {readOnly && (
        <ReadonlyWarning className="mb-8" status={props.calculatedStatus} />
      )}
      {errors?.length > 0 && (
        <Feedback className="mb-8">{errors[0].message}</Feedback>
      )}
      {children}
    </Page>
  );
};
