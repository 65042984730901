import { FC } from "react";

export const TitleSection : FC = ({ children }) => {
  return (
    <div className="mt-3 mb-8 space-y-1">
      {children}
    </div>
  );
};

export const PageTitle : FC = ({ children }) => {
  return (
    <h1 className="text-xl text-semibold">
      {children}
    </h1>
  );
};
