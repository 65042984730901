import { forwardRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import cssClass from "classnames";

import { Uploader, WidgetAPI } from "components/atoms/uploader";

type AvatarProps = {
  onChange: (value: string) => void;
  readOnly: boolean;
  value: string;
};

export const Avatar = forwardRef<HTMLInputElement, AvatarProps>(
  ({ onChange, readOnly, value }, _) => {
    const { t } = useTranslation();

    const refWidget = useRef<WidgetAPI>();
    const openWidget = () => {
      if (refWidget.current) {
        refWidget.current.openDialog("file");
      }
    };

    return (
      <>
        <div
          className="flex flex-col justify-center w-48 h-48 bg-gray-200 rounded-full"
          style={
            value
              ? {
                  backgroundImage: `url(${value}`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }
              : null
          }
        >
          {!readOnly && (
            <button
              className={"w-48 h-48 text-sm rounded-full"}
              type="button"
              onClick={openWidget}
            >
              <span className={cssClass({ "p-2 rounded bg-overlay": !!value })}>
                {t("step-profile.form.avatar")}
              </span>
            </button>
          )}
        </div>
        {!readOnly && (
          <div className="hidden">
            <Uploader
              ref={refWidget}
              onChange={(file) => {
                onChange(file.cdnUrl);
              }}
              value={value}
            />
          </div>
        )}
      </>
    );
  }
);
