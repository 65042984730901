import { forwardRef } from "react";
import { FileInfo, Widget, WidgetAPI } from "@uploadcare/react-widget";

type UploaderProps = {
  filesCount ?: number
  onChange ?: (file : FileInfo) => void;
  value : string;
};

export type { WidgetAPI };

export const Uploader = forwardRef<WidgetAPI, UploaderProps>(
  ({ filesCount = 1, onChange, value }, ref) => {
    /**
     * More info about the widget:
     * https://uploadcare.com/docs/uploads/file-uploader-options
     */
    return (
      <div className="flex items-center justify-center flex-nowrap">
        <Widget
          ref={ref}
          clearable={false}
          /**
           * Forces files uploaded with a file uploader not to be stored.
           * It means they are going to be removed after 24hours.
           * https://uploadcare.com/docs/uploads/file-uploader-options/#option-do-not-store
           */
          doNotStore
          imagesOnly
          previewStep
          locale="it"
          multiple={filesCount > 1}
          multipleMax={filesCount}
          publicKey={window.ENV.REACT_APP_UPLOADCARE_API_KEY}
          onChange={onChange}
          tabs="file facebook instagram gdrive"
          value={value}
        />
      </div>
    );
  }
);
