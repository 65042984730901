import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import cssClass from "classnames";

import { Error } from "./field-error";

type Option = {
  image : string;
  label : string;
  value : string;
}

type RadioGroupImageProps = {
  error ?: string;
  legend ?: string;
  name : string;
  options : Option[];
  readOnly ?: boolean;
  register : UseFormRegister<any>;
  value : string;
};

export const RadioGroupImage : FC<RadioGroupImageProps> = ({ error, legend, name, options, readOnly, register, value }) => {
  return (
    <div className="relative space-y-5" role="group">
      {legend && <Legend>{legend}</Legend>}
      <div className="grid grid-cols-2 gap-8">
        {options.map(
          ({ image, label, value: elValue }) => {
            const checked = value === elValue;
            return (
              <label
                key={elValue}
                className="flex flex-col items-center space-y-3 text-center"
                onClick={readOnly ? (e) => { e.preventDefault(); } : undefined}
              >
                <span
                  className={
                    cssClass(
                      "flex justify-center items-center text-center border rounded-md shadow-md w-36 h-36",
                      { "opacity-40": checked === false },
                      readOnly
                        ? "cursor-default"
                        : "cursor-pointer",
                    )
                  }
                >
                  <input
                    className="sr-only"
                    {...register(name)}
                    type="radio"
                    data-testid={`${name}_${elValue}`}
                    readOnly={readOnly}
                    value={elValue}
                  />
                  <img alt={label} src={`/checkbox/${image}`} className="max-w-[96px]" />
                </span>
                <span className={cssClass({ "text-black": checked, "text-gray-700": checked === false }, "text-sm")}>{label}</span>
              </label>
            );
          }
        )}
      </div>
      {error && (
        <Error>{error}</Error>
      )}
    </div>
  );
};

const Legend : FC = ({ children }) => {
  return (
    <legend className="text-sm font-semibold">
      {children}
    </legend>
  );
};
