import {IAnalyticsTracker, SetUserInput} from "./IAnalyticsTracker";
import ReactGA from "react-ga4";

export class GoogleAnalyticsTracker implements IAnalyticsTracker {
    candidacyRejected(): void {
      ReactGA.event({
        action: "CandidacyRejected",
        category: "candidacy",
      });
    }
    candidacyApproved(): void {
      ReactGA.event({
        action: "CandidacyApproved",
        category: "candidacy",
      });
    }
    setUser(data: SetUserInput): void | Promise<void> {
      ReactGA.gtag("set", "user_data", {
        "email": data.email,
      });
    }
    locationStepCompleted(): void {
        ReactGA.event({
          action: "LocationStepCompleted",
          category: "candidacy",
        });
    }
    homePhotosStepCompleted(): void {
      ReactGA.event({
        action: "HomePhotosStepCompleted",
        category: "candidacy",
      });
    }
    homeAccessibilityStepCompleted(): void {
      ReactGA.event({
        action: "HomeAccessibilityStepCompleted",
        category: "candidacy",
      });
    }
    homeInfoStepCompleted(): void {
      ReactGA.event({
        action: "HomeInfoStepCompleted",
        category: "candidacy",
      });
    }
    cuisinePhotosStepCompleted(): void {
      ReactGA.event({
        action: "CuisinePhotosStepCompleted",
        category: "candidacy",
      });
    }
    cuisineInfoStepCompleted(): void {
      ReactGA.event({
        action: "CuisineInfoStepCompleted",
        category: "candidacy",
      });
    }
    proposalStepCompleted(): void {
      ReactGA.event({
        action: "ProposalStepCompleted",
        category: "candidacy",
      });
    }
    profileStepCompleted(): void {
      ReactGA.event({
        action: "ProfileStepCompleted",
        category: "candidacy",
      });
    }
    certificatesStepCompleted(): void {
      ReactGA.event({
        action: "CertificatesStepCompleted",
        category: "candidacy",
      });
    }
    extraEarnStepCompleted(): Promise<void> {
      if (!ReactGA.isInitialized) {
        return Promise.resolve();
      }
      return new Promise((resolve) => {
        ReactGA.gtag("event", "ExtraEarnStepCompleted", {
          event_category: "candidacy",
          event_callback: () => resolve(),
        });
      });
    }
    beginCandidacy(): void {
      ReactGA.event({
        action: "BeginCandidacy",
        category: "candidacy",
      });
    }
    endCandidacy(): Promise<void> {
      if (!ReactGA.isInitialized) {
        return Promise.resolve();
      }
      return new Promise((resolve) => {
        ReactGA.gtag("event", "EndCandidacy", {
          event_category: "candidacy",
          event_callback: () => resolve(),
        });
      });
    }
}
