import { FC } from "react";
import cssClass from "classnames";
import { UseFormRegister } from "react-hook-form";
import { Error } from "./field-error";

type Option = {
  label : string;
  value : string;
}

type RadioGroupProps = {
  error ?: string;
  legend ?: string;
  name : string;
  options : Option[];
  readOnly ?: boolean;
  register : UseFormRegister<any>;
  vertical ?: boolean;
};

export const RadioGroup : FC<RadioGroupProps> = ({ error, legend, name, options, readOnly, register, vertical }) => {
  return (
    <div className="space-y-3" role="group">
      {legend && <Legend>{legend}</Legend>}
      <div className={cssClass({ "flex-col space-y-3": vertical, "space-x-5": !vertical }, "flex")}>
        {options.map(
          ({ label, value }) => {
            return (
              <label key={value} className="flex items-center space-x-2">
                <input
                  key={value}
                  data-testid={`${name}_${value}`}
                  {...register(name)}
                  type="radio"
                  onClick={readOnly ? (e) => { e.preventDefault(); } : undefined}
                  readOnly={readOnly}
                  value={value}
                />
                <span>{label}</span>
              </label>
            );
          }
        )}
      </div>
      {error && (
        <Error>{error}</Error>
      )}
    </div>
  );
};

const Legend : FC = ({ children }) => {
  return (
    <legend className="text-sm font-semibold">
      {children}
    </legend>
  );
};
