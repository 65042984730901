import {FC, useCallback, useRef, useState} from "react";
import {nanoid} from "nanoid";
import useBool from "@bscop/use-bool";

import {CandidacyStatus} from "graphql/types";

import {useStepExtra} from "hooks/use-step-extra";

import {IconSpinner} from "components/atoms/icons";
import {FormExtra} from "components/organisms/form-extra";

import {Layout} from "./atoms/layout";
import {AnalyticsTrackerComposite} from "../../../analytics/AnalyticsTrackerComposite";

export const StepExtra : FC = () => {
  const { data, isLoading } = useStepExtra();

  const [requestPending, onRequestStart, onRequestEnd] = useBool(false);

  const [disabled, setDisabled] = useState(true);
  const onValidation = useCallback((isValid) => setDisabled(!isValid), []);

  const refFormId = useRef("_" + nanoid());

  if (isLoading) {
    return (
      <Layout disabled isLoading>
        <div className="flex justify-center mt-10">
          <IconSpinner />
        </div>
      </Layout>
    );
  }

  const { errors, navigation, calculatedStatus, ...initialValues } = data;

  const readOnly = calculatedStatus === CandidacyStatus.Completed || calculatedStatus === CandidacyStatus.Accepted;

  return (
    <Layout
      calculatedStatus={calculatedStatus}
      disabled={disabled}
      errors={errors}
      formId={refFormId.current}
      navigation={navigation}
      readOnly={readOnly}
      requestPending={requestPending}
    >
     <FormExtra
        id={refFormId.current}
        initialValues={initialValues}
        onRequestEnd={onRequestEnd}
        onRequestStart={onRequestStart}
        onValidation={onValidation}
        proceedOnSuccess={navigation.nextStep.status !== "COMPLETE"}
        readOnly={readOnly}
        onSuccess={async () => await AnalyticsTrackerComposite.instance().extraEarnStepCompleted()}
      />
    </Layout>
  );
};
