import { forwardRef, ReactNode } from "react";
import { Error } from "./field-error";

type InputFieldProps = {
  error ?: string;
  label : string | ReactNode;
  name : string;
  readOnly ?: boolean;
  value : string;
};

export const Checkbox = forwardRef<HTMLInputElement, InputFieldProps>(
  ({ error, label, ...props }, ref) => {
    return (
      <div>
        <label className="inline-flex items-start space-x-2">
          <input
            ref={ref}
            {...props}
            type="checkbox"
            className="w-5 h-5 shrink-0"
            data-testid={`${props.name}_${props.value}`}
            onClick={props.readOnly ? (e) => { e.preventDefault(); } : undefined}
          />
          <span className="text-sm cursor-pointer">
            {label}
          </span>
        </label>
        {error && (
          <Error className="pl-7">{error}</Error>
        )}
      </div>
    );
  }
);
