import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MoveStep } from "graphql/types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { DataCusinePhotos } from "hooks/use-step-cusine-photos";
import { useUpdateCandidacy } from "hooks/use-update-candidacy";

import { useCandidacy } from "state/candidacy";
import { setCompleted } from "state/candidacy/actions/set-completed";

import { Uploader, WidgetAPI } from "components/atoms/uploader";
import { Gallery } from "components/atoms/gallery";
import { DialogFormError } from "components/molecules/dialog-form-error";

type FormProps = {
  candidacyId ?: string;
  id : string;
  initialValues: DataCusinePhotos;
  onRequestEnd : () => void;
  onRequestStart : () => void;
  onValidation : (isValid : boolean) => void;
  proceedOnSuccess ?: boolean;
  readOnly ?: boolean;
  onSuccess?: () => void;
};

export const FormCusinePhotos : FC<FormProps> = ({
  candidacyId,
  id,
  initialValues,
  onRequestEnd,
  onRequestStart,
  onValidation,
  proceedOnSuccess = true,
  readOnly,
  onSuccess,
}) => {
  const [, dispatch] = useCandidacy();

  const refWidget = useRef<WidgetAPI>();
  const openWidget = () => {
    if (refWidget.current) {
      refWidget.current.openDialog("file");
    }
  };

  const schema = useValidationSchema();

  const {
    formState: { isValid },
    getValues,
    handleSubmit,
    register,
    setValue,
  } = useForm<DataCusinePhotos>({
    defaultValues: initialValues,
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(
    () => {
      onValidation(isValid);
    },
    [isValid, onValidation]
  );

  useEffect(
    () => {
      onValidation(isValid);
    },
    [isValid, onValidation]
  );

  const [operationError, setOperationError] = useState(null);
  const isDialogErrorOpen = operationError != null;

  const { mutate, isLoading } = useUpdateCandidacy(
    candidacyId,
    {
      onError () {
        setOperationError("UNKNOWN");
      },
      /**
       * candidacyId is set only when the component is rendered in backoffice area;
       * in this case we don't want to render the confirmation dialog.
       */
      onSuccess: candidacyId
        ? null
        : (res) => {
          if (res.error) {
            setOperationError(res.error);
            return;
          } else if (res.nextStep === "NONE") {
            dispatch(setCompleted(true));
          }
          onSuccess?.();
        },
      proceedOnSuccess,
    }
  );

  useEffect(
    () => {
      if (isLoading) {
        onRequestStart();
      } else {
        onRequestEnd();
      }
    },
    [isLoading, onRequestStart, onRequestEnd]
  );

  const onSubmit = (payload : DataCusinePhotos) => {
    mutate({ ...payload, move: proceedOnSuccess ? MoveStep.Next : MoveStep.None });
  };

  const photos = getValues("cusinePhotos");

  return (
    <>
      <form className="flex flex-col items-center justify-center flex-1" id={id} noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="max-w-[540px] mx-auto space-y-2">
          {!readOnly && (
            <div className="flex justify-center">
              <Uploader
                {...register("cusinePhotos")}
                ref={refWidget}
                filesCount={10}
                onChange={
                  (file) => {
                    setValue("cusinePhotos", file.cdnUrl, { shouldValidate: true });
                  }
                }
                value={photos}
              />
            </div>
          )}
          <Gallery galleryUrl={photos} onOpen={openWidget} size="260x175" />
        </div>
      </form>
      <DialogFormError
        close={() => { setOperationError(null); }}
        error={operationError}
        isOpen={isDialogErrorOpen}
      />
    </>
  );
};

const useValidationSchema = () => {
  const { t } = useTranslation();
  return yup.object()
    .shape({
      cusinePhotos: yup.string()
        .required(t("errorRequired")),
    });
};

