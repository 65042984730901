import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MoveStep } from "graphql/types";

import { useBooleanOptions } from "hooks/use-boolean-options";
import { DataCertificates } from "hooks/use-step-certificates";
import { useUpdateCandidacy } from "hooks/use-update-candidacy";

import { useCandidacy } from "state/candidacy";
import { setCompleted } from "state/candidacy/actions/set-completed";

import { FieldCol, FieldRow } from "components/atoms/form/grid";
import { SectionTitle } from "components/atoms/form/section-title";
import { Select } from "components/atoms/form/select";
import { CheckboxGroup } from "components/atoms/form/checkbox-group";
import { DialogFormError } from "components/molecules/dialog-form-error";
import { InputField } from "components/atoms/form/input";
import { useRegions } from "hooks/use-region";

type FormProps = {
  candidacyId ?: string;
  id : string;
  initialValues : DataCertificates;
  onRequestEnd : () => void;
  onRequestStart : () => void;
  onValidation : (isValid : boolean) => void;
  proceedOnSuccess ?: boolean;
  readOnly ?: boolean;
  onSuccess?: () => void;
};

export const FormCertificates : FC<FormProps> = ({
  candidacyId,
  id,
  initialValues,
  onRequestEnd,
  onRequestStart,
  onValidation,
  proceedOnSuccess = true,
  readOnly,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const regions = useRegions();

  const [, dispatch] = useCandidacy();

  const schema = useValidationSchema();

  const options = useBooleanOptions();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    resetField,
    watch,
  } = useForm<DataCertificates>({
    defaultValues: {
      ...initialValues,
      haccpRegion: initialValues.haccpRegion || "",
      haccpStart: initialValues.haccpStart
        ? initialValues.haccpStart.split("T")[0]
        : "",
    },
    delayError: 1000,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const haccp = watch("haccp");

  useEffect(
    () => {
      onValidation(isValid);
    },
    [isValid, onValidation]
  );

  useEffect(
    () => {
      if (haccp === "no") {
        resetField("haccpRegion");
        resetField("haccpStart");
      }
    },
    [haccp, resetField]
  );

  const [operationError, setOperationError] = useState(null);
  const isDialogErrorOpen = operationError != null;

  const { mutate, isLoading } = useUpdateCandidacy(
    candidacyId,
    {
      onError () {
        setOperationError("UNKNOWN");
      },
      /**
       * candidacyId is set only when the component is rendered in backoffice area;
       * in this case we don't want to render the confirmation dialog.
       */
      onSuccess: candidacyId
        ? null
        : (res) => {
          if (res.error) {
            setOperationError(res.error);
            return;
          } else if (res.nextStep === "NONE") {
            dispatch(setCompleted(true));
          }
          onSuccess?.();
        },
      proceedOnSuccess,
    }
  );

  useEffect(
    () => {
      if (isLoading) {
        onRequestStart();
      } else {
        onRequestEnd();
      }
    },
    [isLoading, onRequestStart, onRequestEnd]
  );

  const onSubmit = (payload : DataCertificates) => {
    mutate({
      ...payload,
      languages: payload.languages || [],
      move: proceedOnSuccess ? MoveStep.Next : MoveStep.None,
    });
  };

  const [today] = (new Date()).toISOString().split("T");

  return (
    <>
      <form className="flex flex-col flex-1" id={id} noValidate onSubmit={handleSubmit(onSubmit)}>
        <FieldCol>
          <CheckboxGroup
            // @ts-ignore
            error={errors.languages?.message}
            legend={t("step-certificates.form.labelLanguages")}
            name="languages"
            options={[
              {
                label: "Italiano",
                value: "it",
              },
              {
                label: "English",
                value: "en",
              },
              {
                label: "Español",
                value: "es",
              },
              {
                label: "Deutsch",
                value: "de",
              },
              {
                label: "Français",
                value: "fr",
              },
            ]}
            readOnly={readOnly}
            register={register}
          />
          <div>
            <SectionTitle>{t("step-certificates.formSectionCertificates")}</SectionTitle>
            <FieldCol>
              <Select
                {...register("haccp")}
                error={errors.haccp?.message}
                label={t("step-certificates.form.labelHACCP")}
                options={options}
                readOnly={readOnly}
              />
              {haccp === "yes" && (
                <FieldRow>
                  <InputField
                    {...register("haccpStart")}
                    error={errors.haccpStart?.message}
                    label={t("step-certificates.form.labelHACCPStart")}
                    max={today}
                    readOnly={readOnly}
                    type="date"
                  />
                  <Select
                    {...register("haccpRegion")}
                    error={errors.haccpRegion?.message}
                    label={t("step-certificates.form.labelHACCPRegion")}
                    options={regions}
                    readOnly={readOnly}
                  />
                </FieldRow>
              )}
              <Select
                {...register("taster1")}
                error={errors.taster1?.message}
                label={t("step-certificates.form.labelTaster1")}
                options={options}
                readOnly={readOnly}
              />
              <Select
                {...register("taster2")}
                error={errors.taster2?.message}
                label={t("step-certificates.form.labelTaster2")}
                options={options}
                readOnly={readOnly}
              />
              <Select
                {...register("sommelier")}
                error={errors.sommelier?.message}
                label={t("step-certificates.form.labelSommelier")}
                options={options}
                readOnly={readOnly}
              />
            </FieldCol>
          </div>
        </FieldCol>
      </form>
      <DialogFormError
        close={() => { setOperationError(null); }}
        error={operationError}
        isOpen={isDialogErrorOpen}
      />
    </>
  );
};

const useValidationSchema = () => {
  const { t } = useTranslation();
  return yup.object()
    .shape({
      languages: yup.array()
        .min(1, t("errorChoiceRequired"))
        .required(t("errorRequired")),
      haccp: yup.string()
        .required(t("errorRequired"))
        .oneOf(["no", "yes"], t("errorInvalid")),
      haccpRegion: yup.string()
        .when("haccp", (haccp) => {
          if (haccp === "yes") {
            return yup.string().required(t("errorRequired"));
          }
        }),
      haccpStart: yup.string()
        .when("haccp", (haccp) => {
          if (haccp === "yes") {
            return yup.string().required(t("errorRequired"));
          }
        }),
      sommelier: yup.string()
        .required(t("errorRequired"))
        .oneOf(["no", "yes"], t("errorInvalid")),
      taster1: yup.string()
        .required(t("errorRequired"))
        .oneOf(["no", "yes"], t("errorInvalid")),
      taster2: yup.string()
        .required(t("errorRequired"))
        .oneOf(["no", "yes"], t("errorInvalid")),
    });
};
