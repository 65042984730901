import { FC, useCallback, useRef, useState } from "react";
import { nanoid } from "nanoid";
import useBool from "@bscop/use-bool";

import { CandidacyStatus } from "graphql/types";

import { useStepCertificates } from "hooks/use-step-certificates";

import { IconSpinner } from "components/atoms/icons";
import { FormCertificates } from "components/organisms/form-certificates";

import { Layout } from "./atoms/layout";
import {AnalyticsTrackerComposite} from "../../../analytics/AnalyticsTrackerComposite";

export const StepCertificates : FC = () => {
  const { data, isLoading } = useStepCertificates();

  const [requestPending, onRequestStart, onRequestEnd] = useBool(false);

  const [disabled, setDisabled] = useState(true);
  const onValidation = useCallback((isValid) => setDisabled(!isValid), []);

  const refFormId = useRef("_" + nanoid());

  if (isLoading) {
    return (
      <Layout disabled isLoading>
        <div className="flex justify-center mt-10">
          <IconSpinner />
        </div>
      </Layout>
    );
  }

  const { errors, navigation, calculatedStatus, ...initialValues } = data;

  const readOnly = calculatedStatus === CandidacyStatus.Completed || calculatedStatus === CandidacyStatus.Accepted;

  return (
    <Layout
      calculatedStatus={calculatedStatus}
      disabled={disabled}
      errors={errors}
      formId={refFormId.current}
      navigation={navigation}
      readOnly={readOnly}
      requestPending={requestPending}
    >
      <FormCertificates
        id={refFormId.current}
        initialValues={initialValues}
        onRequestEnd={onRequestEnd}
        onRequestStart={onRequestStart}
        onValidation={onValidation}
        proceedOnSuccess={navigation.nextStep.status !== "COMPLETE"}
        readOnly={readOnly}
        onSuccess={() => AnalyticsTrackerComposite.instance().certificatesStepCompleted()}
      />
    </Layout>
  );
};
