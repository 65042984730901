import { FC, ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cssClass from "classnames";
import { CandidacyStatus, Navigation, NextStatus } from "graphql/types";
import { useCandidacy, useCandidacyCompanyFlow } from "state/candidacy";
import { setCompleted } from "state/candidacy/actions/set-completed";
import { useImage } from "hooks/use-image";
import { IconArrowLeft, IconSpinner } from "components/atoms/icons";
import { Logo } from "components/atoms/logo";
import { Dialog, DialogProps } from "components/atoms/dialog";
import { FadeIn } from "components/atoms/fade-in";
import { SafeBoundary } from "components/atoms/error-boundary";
import { MAP_STEP_ROUTE } from "routes";
import { useAuth0 } from "@auth0/auth0-react";
import { AnalyticsTrackerComposite } from "../../../analytics/AnalyticsTrackerComposite";
import { cn } from "utils";
import { addCompanySubstringToKey } from "utils/steps";

const getProgressPercentage = (current: number, total: number) => {
  return total ? `${(current * 100) / total}%` : "0";
};

export type PageProps = {
  calculatedStatus?: CandidacyStatus;
  disabled?: boolean;
  formId?: string;
  isLoading?: boolean;
  HighlightedText?: ReactNode;
  navigation?: Navigation;
  requestPending?: boolean;
};

/**
 * Added these interpolation in the safelist of tailwind config
 */
const STEP_BACKGROUNDS = (step: number, isCompanyFlow: boolean) => {
  return `md:bg-step-${isCompanyFlow ? "company-" : ""}${step}`;
};

export const Page: FC<PageProps> = ({
  calculatedStatus,
  children,
  disabled,
  formId,
  HighlightedText,
  isLoading,
  navigation,
  requestPending,
  ...q
}) => {
  const { t } = useTranslation();

  const { user, isAuthenticated } = useAuth0();
  const isCompanyFlow = useCandidacyCompanyFlow();

  useEffect(() => {
    if (isAuthenticated && user?.email) {
      AnalyticsTrackerComposite.instance().setUser({
        email: user?.email,
        id: user?.email,
      });
    }
  }, [isAuthenticated, user?.email]);

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (!isLoading) {
      setVisible(true);
    }
  }, [isLoading]);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const step = navigation?.actualStepNumber;
  const steps = navigation?.totalStepsNumber;

  const { step: previousStep } = navigation?.previousStep || {};
  const { status: nextStepStatus, step: nextStep } = navigation?.nextStep || {};

  const renderPreviousStepButton = !!previousStep && previousStep !== "NONE";

  // We don't want to render the proceed/save button
  // when the candidacy is frozen (status: NEXT_READ_ONLY),
  // and there's no next step (step: NONE).
  const renderNextStepButton =
    !!nextStep && !(nextStepStatus === "NEXT_READ_ONLY" && nextStep === "NONE");

  const bgReady = useImage(
    step
      ? `/backgrounds/step_${isCompanyFlow ? "company_" : ""}${step}.jpg`
      : null
  );

  const isFirstSending =
    calculatedStatus !== CandidacyStatus.Withchangerequests;
  return (
    <>
      <div className="flex flex-col h-screen overflow-auto md:flex-row md:overflow-hidden">
        <Col
          className={cn(
            bgReady
              ? `bg-cover bg-gradient ${STEP_BACKGROUNDS(step, isCompanyFlow)}`
              : "bg-zinc-50",
            "flex-shrink max-h-[76px] p-3 pl-0 md:max-h-full md:max-w-[50%]"
          )}
        >
          <div className="flex items-center justify-between">
            <a href="/" className="outline-none">
              <span className="sr-only">Cesarine</span>
              <Logo />
            </a>
            <div className="flex space-x-3 md:hidden">
              <a className="btn-pillow-i" href="mailto:community@cesarine.com">
                {t("ctaHelp")}
              </a>
            </div>
          </div>
          <div className="hidden pr-2 mt-16 mb-10 md:block">
            <FadeIn visible={visible}>{HighlightedText}</FadeIn>
          </div>
        </Col>
        <Col>
          <header className="p-3">
            <div className="justify-end hidden space-x-3 md:flex">
              <a className="btn-pillow" href="mailto:community@cesarine.com">
                {t("ctaHelp")}
              </a>
            </div>
          </header>
          <main className="flex flex-col flex-1 p-3 overflow-y-auto">
            <SafeBoundary>{children}</SafeBoundary>
          </main>
          {(renderPreviousStepButton || renderNextStepButton) && (
            <footer className="mt-5 border-t border-gray-200">
              <div
                className="border-t-4 -mt-[2px] border-black"
                style={{ width: getProgressPercentage(step, steps) }}
              />
              <div className="flex justify-between p-3 text-base">
                {renderPreviousStepButton ? (
                  <Link
                    className="flex items-center py-2 space-x-2 outline-none focus:underline"
                    data-testid="back"
                    to={`${isCompanyFlow ? "/company" : ""}${MAP_STEP_ROUTE.get(
                      previousStep
                    )}`}
                  >
                    <IconArrowLeft aria-hidden="true" />
                    <span>{t("ctaBack")}</span>
                  </Link>
                ) : (
                  <div />
                )}
                {renderNextStepButton && (
                  <button
                    type={formId ? "submit" : "button"}
                    className={cssClass(
                      { "opacity-50": disabled || requestPending },
                      "flex items-center space-x-3 px-5 py-2 text-white rounded-md bg-brandRed"
                    )}
                    data-testid="proceed"
                    disabled={disabled || requestPending}
                    form={formId}
                    onClick={
                      nextStep === "NONE"
                        ? (event) => {
                            // When user reached the last step in the candidacy flow
                            // we render a confirmation message before letting they
                            // send the Candidacy.
                            event.preventDefault();
                            setShowConfirmDialog(true);
                          }
                        : undefined
                    }
                  >
                    <span>{t(getNextButtonLabelKey(nextStepStatus))}</span>
                    {requestPending && <IconSpinner />}
                  </button>
                )}
              </div>
            </footer>
          )}
        </Col>
      </div>
      <DialogConfirmSendCandidacy
        close={() => setShowConfirmDialog(false)}
        formId={formId}
        isFirstSending={isFirstSending}
        isOpen={showConfirmDialog}
      />
      <DialogConfirmSentCandidacy isFirstSending={isFirstSending} />
    </>
  );
};

type ColProps = {
  className?: string;
};

const Col: FC<ColProps> = ({ children, className }) => {
  return (
    <div
      className={cssClass(className, "flex flex-col flex-1 justify-between")}
    >
      {children}
    </div>
  );
};

const getNextButtonLabelKey = (status: NextStatus) => {
  switch (status) {
    case NextStatus.Complete:
      return "ctaSendCandidacy";

    case NextStatus.Save:
      return "ctaSave";

    case NextStatus.Continue:
    case NextStatus.NextError:
    default:
      return "ctaNext";
  }
};

type DialogConfirmSendCandidacyProps = Pick<DialogProps, "close" | "isOpen"> & {
  formId: string;
  isFirstSending: boolean;
};

const DialogConfirmSendCandidacy: FC<DialogConfirmSendCandidacyProps> = ({
  formId,
  isFirstSending,
  ...props
}) => {
  const { t } = useTranslation();
  const isComapanyFlow = useCandidacyCompanyFlow();

  return (
    <Dialog
      {...props}
      size="max-w-lg"
      title={
        isFirstSending
          ? t(addCompanySubstringToKey("sendCandidacy", isComapanyFlow))
          : t("sendCandidacyEdit")
      }
    >
      <div className="space-y-10">
        <div className="space-y-1">
          <p className="whitespace-pre-line">
            {isFirstSending
              ? t(
                  addCompanySubstringToKey(
                    "confirmSendCandidacy",
                    isComapanyFlow
                  )
                )
              : t("confirmSendCandidacyEdit")}
          </p>
        </div>
        <div className="flex justify-end space-x-5 text-base">
          <button type="button" onClick={props.close}>
            {t("ctaCancel")}
          </button>
          <button
            type="submit"
            data-testid="submit-candidacy"
            form={formId}
            onClick={props.close}
            className="px-5 py-3 text-white rounded bg-brandYellow"
          >
            {t("ctaConfirm")}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

type DialogConfirmSentCandidacyProps = {
  isFirstSending: boolean;
};

const DialogConfirmSentCandidacy: FC<DialogConfirmSentCandidacyProps> = ({
  isFirstSending,
}) => {
  const { t } = useTranslation();
  const isCompanyFlow = useCandidacyCompanyFlow();

  const [candidacy, dispatch] = useCandidacy();

  const reportConversionAndRedirect = async (url: string) => {
    await AnalyticsTrackerComposite.instance().endCandidacy();
    if (typeof url === "string") {
      window.location.href = url;
    }
  };

  const close = async () => {
    dispatch(setCompleted(false));
    await reportConversionAndRedirect("https://cesarine.com/");
  };

  return (
    <Dialog
      isOpen={candidacy.complete}
      close={close}
      size="max-w-lg"
      title={
        isFirstSending
          ? t(addCompanySubstringToKey("confirmCandidacySent", isCompanyFlow))
          : t("confirmCandidacySentEdit")
      }
    >
      <div className="space-y-8">
        <div className="space-y-1">
          <p className="whitespace-pre-line">
            {isFirstSending
              ? t(
                  addCompanySubstringToKey(
                    "confirmCandidacySentMessage",
                    isCompanyFlow
                  )
                )
              : t("confirmCandidacySentMessageEdit")}
          </p>
          <p className="text-sm text-secondary">
            {isFirstSending
              ? t(
                  addCompanySubstringToKey(
                    "confirmSendCandidacyNext",
                    isCompanyFlow
                  )
                )
              : t("confirmSendCandidacyNextEdit")}
          </p>
        </div>
        <div className="flex justify-end space-x-5">
          <button
            type="button"
            onClick={close}
            className="px-5 py-2 text-sm text-white rounded bg-brandYellow"
          >
            {t("ctaCloseDialog")}
          </button>
        </div>
      </div>
    </Dialog>
  );
};
