import { FC, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { MoveStep } from "graphql/types";
import {
  useForm,
  UseFormGetValues,
  UseFormSetFocus,
  UseFormSetValue,
} from "react-hook-form";
import { Suggestion } from "use-places-autocomplete";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { DataLocation } from "hooks/use-step-location";
import { useUpdateCandidacy } from "hooks/use-update-candidacy";

import { useCandidacy } from "state/candidacy";
import { setCompleted } from "state/candidacy/actions/set-completed";

import {
  InputFieldAddress,
  Coordinates,
} from "components/atoms/form/address-autocomplete";
import { Checkbox } from "components/atoms/form/checkbox";
import { TextButton } from "components/atoms/text-button";
import { DialogFormError } from "components/molecules/dialog-form-error";

import { resolveAddress } from "utils/geo/resolve-address";
import { join } from "utils/string/join";

import { InputField } from "./input";
import { InputPhoneField } from "components/atoms/form/input-phone";
import { FieldRow } from "components/atoms/form/grid";
import { Select } from "components/atoms/form/select";
import { InputField as TextInput } from "components/atoms/form/input";
import { CandidacyType } from "chunks/candidatura";

const PHONE_PREFIX = "+39";

type FormProps = {
  candidacyType?: CandidacyType | null;
  candidacyId?: string;
  id: string;
  initialValues: DataLocation;
  onRequestEnd: () => void;
  onRequestStart: () => void;
  onValidation: (isValid: boolean) => void;
  proceedOnSuccess?: boolean;
  readOnly?: boolean;
  onSuccess?: () => void;
};

export const FormLocation: FC<FormProps> = ({
  candidacyType,
  candidacyId,
  id,
  initialValues,
  onRequestEnd,
  onRequestStart,
  onValidation,
  proceedOnSuccess = true,
  readOnly,
  onSuccess,
}) => {
  const refAutocompleteInput = useRef<HTMLInputElement>();

  const { t } = useTranslation();

  const [candidacy, dispatch] = useCandidacy();

  const [selectedSuggestion, setSelectedSuggestion] = useState<Suggestion>();

  const schema = useValidationSchema();

  const {
    formState: { errors, isValid },
    getValues,
    handleSubmit,
    register,
    reset,
    setFocus,
    setValue,
    watch,
  } = useForm<DataLocation>({
    defaultValues: {
      ...initialValues,
      /**
       * `contract` & `contractClauses` are not persisted on backend,
       * but are mandatory to proceed to next step.
       * so, if any other value is set, we assume at some point the user
       * marked these checkbox.
       */
      contract: !!initialValues.phone ? "yes" : undefined,
      contractClauses: !!initialValues.phone ? "yes" : undefined,
      phone: initialValues.phone?.startsWith(PHONE_PREFIX)
        ? initialValues.phone.replace(PHONE_PREFIX, "")
        : initialValues.phone,
    },
    delayError: 1000,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    onValidation(isValid);
  }, [isValid, onValidation]);

  const hasResolvedAddress = useSetAddressFromSuggestion(
    selectedSuggestion,
    initialValues,
    setValue
  );

  useSetFocusOnFirstInvalidField(hasResolvedAddress, getValues, setFocus);

  const values = watch();

  const [coords, setCoordinates] = useState<Coordinates>(null);

  const resetSuggestion = () => {
    setSelectedSuggestion(null);

    // I don't want to reset `privacy` if already set.
    reset({
      addressRegion: "",
      city: "",
      civic: "",
      country: "",
      province: "",
      provinceLong: "",
      street: "",
      zip: "",
    });

    if (refAutocompleteInput.current) {
      refAutocompleteInput.current.focus();
    }
  };

  const [operationError, setOperationError] = useState(null);
  const isDialogErrorOpen = operationError != null;

  const { mutate, isLoading } = useUpdateCandidacy(candidacyId, {
    onError() {
      setOperationError("UNKNOWN");
    },
    /**
     * candidacyId is set only when the component is rendered in backoffice area;
     * in this case we don't want to render the confirmation dialog.
     */
    onSuccess: candidacyId
      ? null
      : (res) => {
          if (res.error) {
            setOperationError(res.error);
            return;
          } else if (res.nextStep === "NONE") {
            dispatch(setCompleted(true));
          }
          onSuccess?.();
        },
    proceedOnSuccess,
  });

  useEffect(() => {
    if (isLoading) {
      onRequestStart();
    } else {
      onRequestEnd();
    }
  }, [isLoading, onRequestStart, onRequestEnd]);

  const onSubmit = async (payload: DataLocation) => {
    const { contract: _1, contractClauses: _2, ...formData } = payload;
    await mutate({
      ...formData,
      ...coords,
      ...(formData.discoveredFrom !== "other"
        ? { otherDescription: undefined }
        : null),
      move: proceedOnSuccess ? MoveStep.Next : MoveStep.None,
      phone: payload.phone.startsWith("+")
        ? payload.phone
        : PHONE_PREFIX + payload.phone,
      candidacyType: candidacyType ?? candidacy.type,
    });
  };

  return (
    <>
      <form
        className="flex flex-col items-center justify-center flex-1 max-w-[540px] space-y-10 mx-auto"
        id={id}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex-1 w-full">
          <div className="flex flex-col space-y-5">
            {/* Phone */}
            <FieldRow>
              <InputPhoneField
                {...register("phone")}
                error={errors.phone?.message}
                label={t("step-profile.form.labelPhone")}
                prefix={PHONE_PREFIX}
                readOnly={readOnly}
              />
              <div className="flex-1" />
            </FieldRow>
            {/* Address */}
            <div className="space-y-2">
              <InputFieldAddress
                ref={refAutocompleteInput}
                label={t("step-location.form.labelLocation")}
                name="location"
                onChange={(suggestion, coordinates) => {
                  setSelectedSuggestion(suggestion);
                  setCoordinates(coordinates);
                }}
                readOnly={hasResolvedAddress}
                value={formatAddress(values)}
              />
              {hasResolvedAddress && (
                <div className="space-y-1">
                  {!readOnly && (
                    <div className="flex justify-end text-sm">
                      <TextButton
                        onClick={resetSuggestion}
                        color="text-brandYellow"
                      >
                        {t("step-location.form.ctaResetAddress")}
                      </TextButton>
                    </div>
                  )}
                  <div className="border divide-y rounded">
                    <RecapBox>
                      <InputField
                        {...register("street")}
                        error={errors.street?.message}
                        label={t("step-location.form.labelAddress")}
                        readOnly={readOnly}
                      />
                      <InputField
                        {...register("civic")}
                        error={errors.civic?.message}
                        label={t("step-location.form.labelCivic")}
                        readOnly={readOnly}
                      />
                    </RecapBox>
                    <RecapBox>
                      <InputField
                        {...register("city")}
                        error={errors.city?.message}
                        label={t("step-location.form.labelCity")}
                        readOnly={readOnly}
                      />
                      <InputField
                        {...register("zip")}
                        error={errors.zip?.message}
                        label={t("step-location.form.labelZip")}
                        readOnly={readOnly}
                      />
                    </RecapBox>
                    <RecapBox>
                      <InputField
                        {...register("province")}
                        error={errors.province?.message}
                        label={t("step-location.form.labelProvince")}
                        readOnly={readOnly}
                      />
                      <InputField
                        {...register("country")}
                        error={errors.country?.message}
                        label={t("step-location.form.labelCountry")}
                        readOnly={readOnly}
                      />
                    </RecapBox>
                  </div>
                </div>
              )}
            </div>
            {/* Discovery */}
            <div className="flex flex-col gap-2">
              <Select
                {...register("discoveredFrom")}
                error={errors.discoveredFrom?.message}
                label={t("step-location.form.labelDiscoveredFrom")}
                options={[
                  { label: t("step-location.form.option_select"), value: "" },
                  { label: t("step-location.form.option_tv"), value: "tv" },
                  {
                    label: t("step-location.form.option_radio"),
                    value: "radio",
                  },
                  {
                    label: t("step-location.form.option_magazines"),
                    value: "magazines",
                  },
                  {
                    label: t("step-location.form.option_instagram"),
                    value: "instagram",
                  },
                  {
                    label: t("step-location.form.option_facebook"),
                    value: "facebook",
                  },
                  {
                    label: t("step-location.form.option_linkedin"),
                    value: "linkedin",
                  },
                  {
                    label: t("step-location.form.option_tiktok"),
                    value: "tiktok",
                  },
                  {
                    label: t("step-location.form.option_events"),
                    value: "events",
                  },
                  {
                    label: t("step-location.form.option_community-member"),
                    value: "community-member",
                  },
                  {
                    label: t("step-location.form.option_contact-direct"),
                    value: "contact-direct",
                  },
                  {
                    label: t("step-location.form.option_word-of-mouth"),
                    value: "word-of-mouth",
                  },
                  {
                    label: t("step-location.form.option_other"),
                    value: "other",
                  },
                ]}
                readOnly={readOnly}
              />
              {values.discoveredFrom === "other" && (
                <TextInput
                  {...register("otherDescription")}
                  error={errors.otherDescription?.message}
                  label={t("step-location.form.labelDiscoveredFromOther")}
                  readOnly={readOnly}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Checkbox
            {...register("contract")}
            error={errors.contract?.message}
            label={
              <Trans i18nKey="step-location.form.labelContract">
                placeholder_1
                <a
                  className="underline text-brandYellow"
                  href="https://cesarine.com/it/legal/terms-and-conditions-cesarine"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  placeholder_2
                </a>
                placeholder_3
              </Trans>
            }
            readOnly={readOnly}
            value="yes"
          />
          <Checkbox
            {...register("contractClauses")}
            error={errors.contractClauses?.message}
            label={
              <div className="flex flex-col">
                <span>{t("step-location.form.labelContractClauses")}</span>
                <ul className="pl-5 list-disc">
                  <li>{t("step-location.form.labelContractClausesEl1")}</li>
                  <li>{t("step-location.form.labelContractClausesEl2")}</li>
                  <li>
                    <Trans i18nKey="step-location.form.labelContractClausesEl3">
                      placeholder_1
                      <a
                        className="underline text-brandYellow"
                        href="https://cesarine.com/it/legal/terms-and-conditions-cesarine"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        placeholder_2
                      </a>
                      placeholder_3
                    </Trans>
                  </li>
                </ul>
              </div>
            }
            readOnly={readOnly}
            value="yes"
          />
          <Checkbox
            {...register("privacy")}
            error={errors.privacy?.message}
            label={
              <Trans i18nKey="step-location.form.labelPrivacy">
                placeholder_1
                <a
                  className="underline text-brandYellow"
                  href="https://cesarine.com/it/legal/privacy-policy"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  placeholder_2
                </a>
                placeholder_3
              </Trans>
            }
            readOnly={readOnly}
            value="yes"
          />
        </div>
      </form>
      <DialogFormError
        close={() => {
          setOperationError(null);
        }}
        error={operationError}
        isOpen={isDialogErrorOpen}
      />
    </>
  );
};

const useValidationSchema = () => {
  const { t } = useTranslation();
  return yup.object().shape({
    city: yup
      .string()
      .required(t("errorRequiredShort"))
      .max(36, t("errorMaxLength", { count: 36 })),
    civic: yup
      .string()
      .required(t("errorRequiredShort"))
      .max(8, t("errorMaxLength", { count: 8 }))
      .matches(/^[\w,-/ ]+$/, t("errorInvalid")),
    contract: yup.string().oneOf(["yes"], t("errorContract")),
    contractClauses: yup.string().oneOf(["yes"], t("errorContractClasuses")),
    country: yup
      .string()
      .required(t("errorRequiredShort"))
      .max(36, t("errorMaxLength", { count: 36 })),
    discoveredFrom: yup
      .string()
      .required(t("errorRequiredShort"))
      .oneOf(
        [
          "tv",
          "radio",
          "magazines",
          "instagram",
          "facebook",
          "linkedin",
          "tiktok",
          "events",
          "community-member",
          "contact-direct",
          "word-of-mouth",
          "other",
        ],
        t("errorInvalidDiscoveredFrom")
      ),
    otherDescription: yup
      .string()
      .nullable()
      .max(255, t("errorMaxLength", { count: 255 }))
      .when("discoveredFrom", {
        is: "other",
        then: yup.string().required(t("errorRequiredShort")),
      }),
    phone: yup
      .string()
      .required(t("errorRequired"))
      .matches(/^\d+$/, t("errorPhone"))
      .min(8, t("errorPhone"))
      .max(16, t("errorPhone")),
    privacy: yup.string().oneOf(["yes"], t("errorPrivacy")),
    province: yup
      .string()
      .required(t("errorRequiredShort"))
      .max(36, t("errorMaxLength", { count: 36 })),
    street: yup
      .string()
      .required(t("errorRequiredShort"))
      .max(36, t("errorMaxLength", { count: 36 })),
    zip: yup
      .string()
      .required(t("errorRequiredShort"))
      .matches(/^[0-9]{5}$/, t("errorInvalid")),
  });
};

const useSetAddressFromSuggestion = (
  suggestion: Suggestion,
  initialLocation: DataLocation,
  setValue: UseFormSetValue<DataLocation>
) => {
  const [addressResolved, setAddressResolved] = useState(false);

  const ref = useRef(true);

  useEffect(
    () => {
      const execEffect = async () => {
        const address = suggestion
          ? await resolveAddress(suggestion)
          : // Only first time the component render,
          // if there are `initialValues` we use those
          // to initialize the form fields
          ref.current
          ? initialLocation
          : {};

        ref.current = false;

        setValue("addressRegion", address.addressRegion || "");
        setValue("street", address.street || "");
        setValue("civic", address.civic || "");
        setValue("city", address.city || "");
        setValue("province", address.province || "");
        setValue("provinceLong", address.provinceLong || "");
        setValue("zip", address.zip || "");
        setValue("country", address.country || "");

        setAddressResolved(Object.values(address).some(Boolean));
      };

      execEffect();
    },
    [setValue, suggestion, JSON.stringify(initialLocation)] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return addressResolved;
};

const useSetFocusOnFirstInvalidField = (
  hasResolvedAddress: boolean,
  getValues: UseFormGetValues<DataLocation>,
  setFocus: UseFormSetFocus<DataLocation>
) => {
  useEffect(() => {
    const getFocusedField = (): keyof DataLocation => {
      const fieldNames: (keyof DataLocation)[] = [
        "street",
        "civic",
        "city",
        "zip",
        "province",
        "country",
      ];
      for (const name of fieldNames) {
        if (!getValues(name)) {
          return name;
        }
      }
    };

    if (hasResolvedAddress) {
      const fieldName = getFocusedField();
      if (fieldName) {
        setFocus(fieldName);
      }
    }
  }, [getValues, hasResolvedAddress, setFocus]);
};

const formatAddress = (address: DataLocation) => {
  const { street, civic, city, zip, province, country } = address;

  return join(", ", join(" ", street, civic), city, zip, province, country);
};

const RecapBox = ({ children }) => {
  return (
    <div className="flex flex-col divide-y xs:divide-y-0 xs:divide-x xs:flex-row">
      {children}
    </div>
  );
};
