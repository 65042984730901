import React, { Dispatch, FC, useReducer } from "react";

export type StateProviderProps <TState, TAction> = {
  children : React.ReactNode;
  initialState ?: TState;
  reducer : (state : TState | undefined, action : TAction) => TState;
};

type ProviderProps <TState, TAction> = { value : [TState, Dispatch<TAction>] };

type StateProviderProps_ <TState, TAction> = StateProviderProps<TState, TAction> & { Provider : FC<ProviderProps<TState, TAction>>; };

export function StateProvider <TState, TAction extends { type : string; }> (props : StateProviderProps_<TState, TAction>) : React.ReactElement {
  const { children, initialState, Provider, reducer } = props;
  const init = () => reducer(initialState, { type: "INIT_" + Date.now() } as TAction);
  return (
    <Provider value={useReducer(reducer, initialState, init)}>
      {children}
    </Provider>
  );
}
