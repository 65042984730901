import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import localeIT from "./locales/it.json";

export const setupI18N = (): void => {
  i18n.use(initReactI18next).init({
    resources: {
      it: {
        translation: localeIT,
      },
    },
    lng: "it",
    fallbackLng: "it",
    debug: false,
  });
};
