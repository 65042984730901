import { FC } from "react";
import cssClass from "classnames";
import { useTranslation } from "react-i18next";
import { CandidacyStatus } from "graphql/types";

type ReadonlyWarningProps = {
  className ?: string;
  status ?: CandidacyStatus;
};

export const ReadonlyWarning : FC<ReadonlyWarningProps> = ({ className, status }) => {
  const { t } = useTranslation();
  return (
    <div role="alert" className={cssClass(className, "py-1 pl-2 ml-1 text-sm font-semibold text-brandYellow border-l-4 border-yellow-600")}>
      <b>{t("warning")}:</b>
      <br/>
      <span className="text-gray-800 whitespace-pre-line">
        {status === CandidacyStatus.Accepted ? t("readonlyStepAccepted") : t("readonlyStep")}
      </span>
    </div>
  );
};
