import { createContext, Dispatch, FC, useContext } from "react";

import {
  StateProvider,
  StateProviderProps,
} from "components/atoms/state-provider";
import { CandidacyAction } from "./actions";
import { CandidacyType, CandidacyTypeEnum } from "chunks/candidatura";

export type CandidacyState = {
  type: CandidacyType;
  complete: boolean;
};

const CandidacyContext =
  createContext<[CandidacyState, Dispatch<CandidacyAction>]>(null);

export const useCandidacy = () => {
  return useContext(CandidacyContext);
};

export const useCandidacyCompanyFlow = () => {
  return useContext(CandidacyContext)[0].type === CandidacyTypeEnum.COMPANY;
};

export const CandidacyProvider: FC<
  StateProviderProps<CandidacyState, CandidacyAction>
> = (props) => {
  return (
    <StateProvider<CandidacyState, CandidacyAction>
      {...props}
      Provider={(state) => {
        return <CandidacyContext.Provider {...state} />;
      }}
    />
  );
};
