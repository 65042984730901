import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { MoveStep } from "graphql/types";

import { DataHomeInformation } from "hooks/use-step-home-information";
import { useUpdateCandidacy } from "hooks/use-update-candidacy";

import { useCandidacy } from "state/candidacy";
import { setCompleted } from "state/candidacy/actions/set-completed";

import { FieldCol } from "components/atoms/form/grid";
import { CheckboxGroupImage } from "components/atoms/form/checkbox-group-images";
import { RadioGroupImage } from "components/atoms/form/radio-group-images";
import { DialogFormError } from "components/molecules/dialog-form-error";
import { addCompanySubstringToKey } from "utils/steps";
import { CandidacyType, CandidacyTypeEnum } from "chunks/candidatura";

type FormProps = {
  candidacyType?: CandidacyType | null;
  candidacyId?: string;
  id: string;
  initialValues: DataHomeInformation;
  onRequestEnd: () => void;
  onRequestStart: () => void;
  onValidation: (isValid: boolean) => void;
  proceedOnSuccess?: boolean;
  readOnly?: boolean;
  onSuccess?: () => void;
};

export const FormHomeInfo: FC<FormProps> = ({
  candidacyType,
  candidacyId,
  id,
  initialValues,
  onRequestEnd,
  onRequestStart,
  onValidation,
  proceedOnSuccess = true,
  readOnly,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const [candidacy, dispatch] = useCandidacy();

  const isCompanyFlow =
    candidacyType === CandidacyTypeEnum.COMPANY ||
    candidacy.type === CandidacyTypeEnum.COMPANY;

  const schema = useValidationSchema(isCompanyFlow);

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    watch,
  } = useForm<DataHomeInformation>({
    defaultValues: initialValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    onValidation(isValid);
  }, [isValid, onValidation]);

  const [operationError, setOperationError] = useState(null);
  const isDialogErrorOpen = operationError != null;

  const { mutate, isLoading } = useUpdateCandidacy(candidacyId, {
    onError() {
      setOperationError("UNKNOWN");
    },
    /**
     * candidacyId is set only when the component is rendered in backoffice area;
     * in this case we don't want to render the confirmation dialog.
     */
    onSuccess: candidacyId
      ? null
      : (res) => {
          if (res.error) {
            setOperationError(res.error);
            return;
          } else if (res.nextStep === "NONE") {
            dispatch(setCompleted(true));
          }
          onSuccess?.();
        },
    proceedOnSuccess,
  });

  useEffect(() => {
    if (isLoading) {
      onRequestStart();
    } else {
      onRequestEnd();
    }
  }, [isLoading, onRequestStart, onRequestEnd]);

  const onSubmit = (payload: DataHomeInformation) => {
    mutate({
      ...payload,
      features: payload.features || [],
      move: proceedOnSuccess ? MoveStep.Next : MoveStep.None,
      candidacyType: candidacyType ?? candidacy.type,
    });
  };

  const selectedKind = watch("kind");

  return (
    <>
      <form id={id} noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-center max-w-[540px] mx-auto">
          <FieldCol>
            {!isCompanyFlow && (
              <RadioGroupImage
                error={errors.kind?.message}
                legend={t("step-home-info.form.labelKinds")}
                name="kind"
                options={[
                  {
                    image: "flat.jpg",
                    label: t("step-home-info.form.kind_flat"),
                    value: "flat",
                  },
                  {
                    image: "attic.jpg",
                    label: t("step-home-info.form.kind_attic"),
                    value: "attic",
                  },
                  {
                    image: "ville.jpg",
                    label: t("step-home-info.form.kind_ville"),
                    value: "ville",
                  },
                  {
                    image: "bb.jpg",
                    label: t("step-home-info.form.kind_bb"),
                    value: "bb",
                  },
                ]}
                readOnly={readOnly}
                register={register}
                value={selectedKind}
              />
            )}
            <CheckboxGroupImage
              legend={t(
                addCompanySubstringToKey(
                  "step-home-info.form.labelFeatures",
                  isCompanyFlow
                )
              )}
              name="features"
              options={[
                {
                  image: "view.jpg",
                  label: t("step-home-info.form.feature_view"),
                  value: "view",
                },
                {
                  image: "garden.jpg",
                  label: t("step-home-info.form.feature_garden"),
                  value: "garden",
                },
                {
                  image: "terace.jpg",
                  label: t("step-home-info.form.feature_terace"),
                  value: "terace",
                },
                {
                  image: "outdoor.jpg",
                  label: t("step-home-info.form.feature_outdoor"),
                  value: "outdoor",
                },
                {
                  image: "winteroutdoor.jpg",
                  label: t("step-home-info.form.feature_winteroutdoor"),
                  value: "winteroutdoor",
                },
                {
                  image: "swimmingpool.jpg",
                  label: t("step-home-info.form.feature_swimmingpool"),
                  value: "swimmingpool",
                },
                {
                  image: "veggarden.jpg",
                  label: t("step-home-info.form.feature_veggarden"),
                  value: "veggarden",
                },
                {
                  image: "orchard.jpg",
                  label: t("step-home-info.form.feature_orchard"),
                  value: "orchard",
                },
                {
                  image: "olivegrove.jpg",
                  label: t("step-home-info.form.feature_olivegrove"),
                  value: "olivegrove",
                },
                {
                  image: "vineyard.jpg",
                  label: t("step-home-info.form.feature_vineyard"),
                  value: "vineyard",
                },
                {
                  image: "barbecue.jpg",
                  label: t("step-home-info.form.feature_barbecue"),
                  value: "barbecue",
                },
                {
                  image: "woodoven.jpg",
                  label: t("step-home-info.form.feature_woodoven"),
                  value: "woodoven",
                },
              ]}
              readOnly={readOnly}
              register={register}
              value={watch("features")}
            />
          </FieldCol>
        </div>
      </form>
      <DialogFormError
        close={() => {
          setOperationError(null);
        }}
        error={operationError}
        isOpen={isDialogErrorOpen}
      />
    </>
  );
};

const useValidationSchema = (isCompanyFlow: boolean) => {
  const { t } = useTranslation();
  return yup.object().shape({
    kind: yup.string().when([], {
      is: () => !isCompanyFlow,
      then: yup
        .string()
        .required(t("errorRequired"))
        .oneOf(["attic", "bb", "flat", "ville"], t("errorInvalid")),
      otherwise: yup.string().nullable(),
    }),
  });
};
