import {IAnalyticsTracker, SetUserInput} from "./IAnalyticsTracker";
import posthog from "posthog-js";

export class PosthogTracker implements IAnalyticsTracker {
    candidacyRejected(): void {
        posthog.capture("CandidacyRejected");
    }
    candidacyApproved(): void {
        posthog.capture("CandidacyApproved");
    }
    setUser(data: SetUserInput): void {
        posthog.identify(data.id, {
          email: data.email,
        });
    }
    beginCandidacy(): void {
        posthog.capture("BeginCandidacy");
    }
    endCandidacy(): void {
        posthog.capture("EndCandidacy");
    }
    locationStepCompleted(): void {
        posthog.capture("LocationStepCompleted");
    }
    homePhotosStepCompleted(): void {
        posthog.capture("HomePhotosStepCompleted");
    }
    homeAccessibilityStepCompleted(): void {
        posthog.capture("HomeAccessibilityStepCompleted");
    }
    homeInfoStepCompleted(): void {
        posthog.capture("HomeInfoStepCompleted");
    }
    cuisinePhotosStepCompleted(): void {
        posthog.capture("CuisinePhotosStepCompleted");
    }
    cuisineInfoStepCompleted(): void {
        posthog.capture("CuisineInfoStepCompleted");
    }
    proposalStepCompleted(): void {
        posthog.capture("ProposalStepCompleted");
    }
    profileStepCompleted(): void {
        posthog.capture("ProfileStepCompleted");
    }
    certificatesStepCompleted(): void {
        posthog.capture("CertificatesStepCompleted");
    }
    extraEarnStepCompleted(): void {
        posthog.capture("ExtraEarnStepCompleted");
    }

}
