import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import cssClass from "classnames";

type Option = {
  image : string;
  label : string;
  value : string;
}

type CheckboxGroupImageProps = {
  legend ?: string;
  name : string;
  options : Option[];
  readOnly ?: boolean;
  register : UseFormRegister<any>;
  value : string[];
};

export const CheckboxGroupImage : FC<CheckboxGroupImageProps> = ({ legend, name, options, readOnly, register, value: values }) => {
  return (
    <div className="relative space-y-5" role="group">
      {legend && <Legend>{legend}</Legend>}
      <div className="grid grid-cols-2 gap-2 xs:gap-4 xs:grid-cols-3">
        {options.map(
          ({ image, label, value }) => {
            const checked = !!values?.includes(value);
            return (
              <label
                key={value}
                className="flex flex-col items-center space-y-3 text-center"
                onClick={readOnly ? (e) => { e.preventDefault(); } : undefined}
              >
                <span
                  className={
                    cssClass(
                      "flex justify-center items-center text-center border rounded-md shadow-md w-28 h-28",
                      { "opacity-40": checked === false },
                      readOnly
                        ? "cursor-default"
                        : "cursor-pointer",
                    )
                  }
                >
                  <input
                    className="sr-only"
                    type="checkbox"
                    {...register(name)}
                    data-testid={`${name}_${value}`}
                    readOnly={readOnly}
                    value={value}
                  />
                  <img alt={label} src={`/checkbox/${image}`} className="max-w-[96px]" />
                </span>
                <span className={cssClass({ "text-black": checked, "text-gray-700": checked === false }, "text-sm")}>{label}</span>
              </label>
            );
          }
        )}
      </div>
    </div>
  );
};

const Legend : FC = ({ children }) => {
  return (
    <legend className="text-sm font-semibold">
      {children}
    </legend>
  );
};
