import { getDetails, Suggestion } from "use-places-autocomplete";

export type Address = {
  addressRegion ?: string;
  city ?: string;
  civic ?: string;
  country ?: string;
  province ?: string;
  provinceLong ?: string;
  street ?: string;
  zip ?: string;
};

export const resolveAddress = async (suggestion : Suggestion) : Promise<Address> => {
  const details = await getDetails({ placeId: suggestion.place_id }) as google.maps.places.PlaceResult;
  const address: Address = {};
  for (const thunk of details.address_components) {
    switch (thunk.types[0]) {
      case "administrative_area_level_1":
        address.addressRegion = thunk.short_name;
        break;
      case "administrative_area_level_2":
        address.province = thunk.short_name;
        address.provinceLong = thunk.long_name || thunk.short_name;
        break;
      case "country":
        address.country = thunk.long_name;
        break;
      case "locality":
        address.city = thunk.long_name;
        break;
      case "postal_code":
        address.zip = thunk.long_name;
        break;
      case "route":
        address.street = thunk.long_name;
        break;
      case "street_number":
        address.civic = thunk.long_name;
        break;
    }
  }
  return address;
};
