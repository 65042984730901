import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { MoveStep } from "graphql/types";

import { useBooleanOptions } from "hooks/use-boolean-options";
import { DataHomeAccessibility } from "hooks/use-step-home-accessibility";
import { useUpdateCandidacy } from "hooks/use-update-candidacy";

import { useCandidacy } from "state/candidacy";
import { setCompleted } from "state/candidacy/actions/set-completed";

import { InputField } from "components/atoms/form/input";
import { FieldCol, FieldRow } from "components/atoms/form/grid";
import { Select } from "components/atoms/form/select";
import { DialogFormError } from "components/molecules/dialog-form-error";
import { CandidacyType, CandidacyTypeEnum } from "chunks/candidatura";

type FormProps = {
  candidacyType?: CandidacyType | null;
  candidacyId?: string;
  id: string;
  initialValues: DataHomeAccessibility;
  onRequestEnd: () => void;
  onRequestStart: () => void;
  onValidation: (isValid: boolean) => void;
  proceedOnSuccess?: boolean;
  readOnly?: boolean;
  onSuccess?: () => void;
};

export const FormHomeAccessibility: FC<FormProps> = ({
  candidacyType,
  candidacyId,
  id,
  initialValues,
  onRequestEnd,
  onRequestStart,
  onValidation,
  proceedOnSuccess = true,
  readOnly,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const [candidacy, dispatch] = useCandidacy();

  const isCompanyFlow =
    candidacyType === CandidacyTypeEnum.COMPANY ||
    candidacy.type === CandidacyTypeEnum.COMPANY;

  const options = useBooleanOptions();

  const schema = useValidationSchema(isCompanyFlow);

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<DataHomeAccessibility>({
    defaultValues: initialValues,
    delayError: 1000,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    onValidation(isValid);
  }, [isValid, onValidation]);

  const [operationError, setOperationError] = useState(null);
  const isDialogErrorOpen = operationError != null;

  const { mutate, isLoading } = useUpdateCandidacy(candidacyId, {
    onError() {
      setOperationError("UNKNOWN");
    },
    /**
     * candidacyId is set only when the component is rendered in backoffice area;
     * in this case we don't want to render the confirmation dialog.
     */
    onSuccess: candidacyId
      ? null
      : (res) => {
          if (res.error) {
            setOperationError(res.error);
            return;
          } else if (res.nextStep === "NONE") {
            dispatch(setCompleted(true));
          }
          onSuccess?.();
        },
    proceedOnSuccess,
  });

  useEffect(() => {
    if (isLoading) {
      onRequestStart();
    } else {
      onRequestEnd();
    }
  }, [isLoading, onRequestStart, onRequestEnd]);

  const onSubmit = (payload: DataHomeAccessibility) => {
    mutate({
      ...payload,
      move: proceedOnSuccess ? MoveStep.Next : MoveStep.None,
      candidacyType: candidacyType ?? candidacy.type,
    });
  };

  return (
    <>
      <form id={id} noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-center max-w-[540px] mx-auto">
          <FieldCol>
            <Select
              {...register("barriers")}
              error={errors.barriers?.message}
              label={t(
                isCompanyFlow
                  ? "step-accessibility.form.labelBarriers_company"
                  : "step-accessibility.form.labelBarriers"
              )}
              options={options}
              readOnly={readOnly}
            />
            <FieldRow>
              {!isCompanyFlow && (
                <InputField
                  {...register("floor")}
                  error={errors.floor?.message}
                  label={t("step-accessibility.form.labelFloor")}
                  readOnly={readOnly}
                />
              )}
              <Select
                {...register("elevator")}
                error={errors.elevator?.message}
                label={t("step-accessibility.form.labelElevator")}
                options={options}
                readOnly={readOnly}
              />
            </FieldRow>
            <FieldRow>
              <InputField
                {...register("seats")}
                error={errors.seats?.message}
                label={t("step-accessibility.form.labelSeats")}
                readOnly={readOnly}
              />
              <InputField
                {...register("seatsOpen")}
                error={errors.seatsOpen?.message}
                label={t("step-accessibility.form.labelSeatsOpen")}
                readOnly={readOnly}
              />
            </FieldRow>
            {!isCompanyFlow && (
              <Select
                {...register("pets")}
                error={errors.pets?.message}
                label={t("step-accessibility.form.labelPets")}
                options={options}
                readOnly={readOnly}
              />
            )}
          </FieldCol>
        </div>
      </form>
      <DialogFormError
        close={() => {
          setOperationError(null);
        }}
        error={operationError}
        isOpen={isDialogErrorOpen}
      />
    </>
  );
};

const useValidationSchema = (isCompanyFlow: boolean) => {
  const { t } = useTranslation();
  return yup.object().shape({
    barriers: yup
      .string()
      .required(t("errorRequired"))
      .oneOf(["no", "yes"], t("errorInvalid")),
    elevator: yup
      .string()
      .required(t("errorRequired"))
      .oneOf(["no", "yes"], t("errorInvalid")),
    floor: yup.string().when([], {
      is: () => !isCompanyFlow,
      then: yup.string().required(t("errorRequired")),
      otherwise: yup.string().nullable(),
    }),
    pets: yup.string().when([], {
      is: () => !isCompanyFlow,
      then: yup
        .string()
        .required(t("errorRequired"))
        .oneOf(["no", "yes"], t("errorInvalid")),
      otherwise: yup.string().nullable(),
    }),
    seats: yup
      .string()
      .required(t("errorRequired"))
      .max(2, t("errorMaxLength", { count: 2 }))
      .matches(/^\d+$/, t("errorInvalidNumber")),
    seatsOpen: yup
      .string()
      .required(t("errorRequired"))
      .max(2, t("errorMaxLength", { count: 2 }))
      .matches(/^\d+$/, t("errorInvalidNumber")),
  });
};
