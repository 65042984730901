import { FC, Fragment } from "react";
import { Transition } from "@headlessui/react";

type FadeInProps = {
  visible ?: boolean;
};

export const FadeIn : FC<FadeInProps> = ({ children, visible }) => {
  return (
    <Transition
      as={Fragment}
      show={visible}
      enter="transition-opacity duration-1000"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-1000"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {children}
    </Transition>
  );
};
