import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { MoveStep } from "graphql/types";

import { DataProposals } from "hooks/use-step-proposals";
import { useUpdateCandidacy } from "hooks/use-update-candidacy";

import { useCandidacy } from "state/candidacy";
import { setCompleted } from "state/candidacy/actions/set-completed";

import { FieldCol } from "components/atoms/form/grid";
import { DialogFormError } from "components/molecules/dialog-form-error";
import { CheckboxGroupImage } from "components/atoms/form/checkbox-group-images";

type FormProps = {
  candidacyId ?: string;
  id : string;
  initialValues: DataProposals;
  onRequestEnd : () => void;
  onRequestStart : () => void;
  proceedOnSuccess ?: boolean;
  readOnly ?: boolean;
  onSuccess?: () => void;
};

export const FormProposals : FC<FormProps> = ({
  candidacyId,
  id,
  initialValues,
  onRequestEnd,
  onRequestStart,
  proceedOnSuccess = true,
  readOnly,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const [, dispatch] = useCandidacy();

  const {
    handleSubmit,
    register,
    watch,
  } = useForm<DataProposals>({
    defaultValues: initialValues,
    mode: "onBlur",
  });

  const [operationError, setOperationError] = useState(null);
  const isDialogErrorOpen = operationError != null;

  const { mutate, isLoading } = useUpdateCandidacy(
    candidacyId,
    {
      onError () {
        setOperationError("UNKNOWN");
      },
      /**
       * candidacyId is set only when the component is rendered in backoffice area;
       * in this case we don't want to render the confirmation dialog.
       */
      onSuccess: candidacyId
        ? null
        : (res) => {
          if (res.error) {
            setOperationError(res.error);
            return;
          } else if (res.nextStep === "NONE") {
            dispatch(setCompleted(true));
          }
          onSuccess?.();
        },
      proceedOnSuccess,
    }
  );

  useEffect(
    () => {
      if (isLoading) {
        onRequestStart();
      } else {
        onRequestEnd();
      }
    },
    [isLoading, onRequestStart, onRequestEnd]
  );

  const onSubmit = (payload : DataProposals) => {
    mutate({
      experiences: payload.experiences || [],
      proposals: payload.proposals || [],
      move: proceedOnSuccess ? MoveStep.Next : MoveStep.None,
    });
  };

  return (
    <>
      <form id={id} noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-center max-w-[540px] mx-auto space-y-8">
          <FieldCol>
            <CheckboxGroupImage
              legend={t("step-proposals.form.labelExperiences")}
              name="experiences"
              options={[
                {
                  image: "pasta1.jpg",
                  label: t("step-proposals.form.experience_pasta1"),
                  value: "pasta1",
                },
                {
                  image: "pasta2.jpg",
                  label: t("step-proposals.form.experience_pasta2"),
                  value: "pasta2",
                },
                {
                  image: "pizza.jpg",
                  label: t("step-proposals.form.experience_pizza"),
                  value: "pizza",
                },
              ]}
              readOnly={readOnly}
              register={register}
              value={watch("experiences")}
            />
          </FieldCol>
          <FieldCol>
            <CheckboxGroupImage
              legend={t("step-proposals.form.labelProposals")}
              name="proposals"
              options={[
                {
                  image: "eat.jpg",
                  label: t("step-proposals.form.proposal_eat"),
                  value: "eat",
                },
                {
                  image: "teaching.jpg",
                  label: t("step-proposals.form.proposal_teaching"),
                  value: "teaching",
                },
                {
                  image: "special.jpg",
                  label: t("step-proposals.form.proposal_special"),
                  value: "special",
                },
                {
                  image: "tour.jpg",
                  label: t("step-proposals.form.proposal_tour"),
                  value: "tour",
                },
              ]}
              readOnly={readOnly}
              register={register}
              value={watch("proposals")}
            />
          </FieldCol>
        </div>
      </form>
      <DialogFormError
        close={() => { setOperationError(null); }}
        error={operationError}
        isOpen={isDialogErrorOpen}
      />
    </>
  );
};
