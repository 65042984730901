import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MoveStep } from "graphql/types";

import { DataHomePhotos } from "hooks/use-step-home-photos";
import { useUpdateCandidacy } from "hooks/use-update-candidacy";

import { useCandidacy } from "state/candidacy";
import { setCompleted } from "state/candidacy/actions/set-completed";

import { Uploader, WidgetAPI } from "components/atoms/uploader";
import { Gallery } from "components/atoms/gallery";
import { DialogFormError } from "components/molecules/dialog-form-error";
import { CandidacyType } from "chunks/candidatura";

type FormProps = {
  candidacyType?: CandidacyType | null;
  candidacyId?: string;
  id: string;
  initialValues: DataHomePhotos;
  onRequestEnd: () => void;
  onRequestStart: () => void;
  onValidation: (isValid: boolean) => void;
  proceedOnSuccess?: boolean;
  readOnly?: boolean;
  onSuccess?: () => void;
};

export const FormHomePhotos: FC<FormProps> = ({
  candidacyType,
  candidacyId,
  id,
  initialValues,
  onRequestEnd,
  onRequestStart,
  onValidation,
  proceedOnSuccess = true,
  readOnly,
  onSuccess,
}) => {
  const [candidacy, dispatch] = useCandidacy();

  const refWidget = useRef<WidgetAPI>();
  const openWidget = () => {
    if (refWidget.current) {
      refWidget.current.openDialog("file");
    }
  };

  const schema = useValidationSchema();

  const {
    formState: { isValid },
    getValues,
    handleSubmit,
    register,
    setValue,
  } = useForm<DataHomePhotos>({
    defaultValues: initialValues,
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    onValidation(isValid);
  }, [isValid, onValidation]);

  const [operationError, setOperationError] = useState(null);
  const isDialogErrorOpen = operationError != null;

  const { mutate, isLoading } = useUpdateCandidacy(candidacyId, {
    onError() {
      setOperationError("UNKNOWN");
    },
    /**
     * candidacyId is set only when the component is rendered in backoffice area;
     * in this case we don't want to render the confirmation dialog.
     */
    onSuccess: candidacyId
      ? null
      : (res) => {
          if (res.error) {
            setOperationError(res.error);
            return;
          } else if (res.nextStep === "NONE") {
            dispatch(setCompleted(true));
          }
          onSuccess?.();
        },
    proceedOnSuccess,
  });

  useEffect(() => {
    if (isLoading) {
      onRequestStart();
    } else {
      onRequestEnd();
    }
  }, [isLoading, onRequestStart, onRequestEnd]);

  const onSubmit = (payload: DataHomePhotos) => {
    mutate({
      ...payload,
      move: proceedOnSuccess ? MoveStep.Next : MoveStep.None,
      candidacyType: candidacyType ?? candidacy.type,
    });
  };

  return (
    <>
      <form
        className="flex flex-col items-center justify-center flex-1"
        id={id}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="max-w-[540px] mx-auto space-y-2">
          {!readOnly && (
            <div className="flex justify-center">
              <Uploader
                {...register("homePhotos")}
                ref={refWidget}
                filesCount={10}
                onChange={(file) => {
                  setValue("homePhotos", file.cdnUrl, { shouldValidate: true });
                }}
                value={getValues("homePhotos")}
              />
            </div>
          )}
          <Gallery
            galleryUrl={getValues("homePhotos")}
            onOpen={openWidget}
            size="260x175"
          />
        </div>
      </form>
      <DialogFormError
        close={() => {
          setOperationError(null);
        }}
        error={operationError}
        isOpen={isDialogErrorOpen}
      />
    </>
  );
};

const useValidationSchema = () => {
  const { t } = useTranslation();
  return yup.object().shape({
    homePhotos: yup.string().required(t("errorRequired")),
  });
};
