import { useEffect, useState } from "react";

export const useImage = (src: string) => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (src) {
      const img = document.createElement("img");
      img.onload = () => {
        setReady(true);
      };
      img.src = src;
    }
  }, [src]);
  return ready;
};
