import { forwardRef } from "react";
import { ChangeHandler } from "react-hook-form";
import cssClass from "classnames";

import { Error } from "./field-error";

export type TextareaProps = {
  error ?: string;
  label : string;
  name : string;
  onChange : ChangeHandler;
  placeholder ?: string;
  readOnly ?: boolean;
  value ?: string;
};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label, error, ...props }, ref) => {
    return (
      <div className="flex-1 space-y-1">
        <label className="flex flex-col space-y-1">
          <span className="text-sm cursor-pointer">
            {label}
          </span>
          <textarea
            ref={ref}
            data-testid={props.name}
            rows={6}
            {...props}
            className={cssClass({ "border-red-700": error }, "p-2 border rounded text-sm")}
          />
        </label>
        {error && (
          <Error>{error}</Error>
        )}
      </div>
    );
  }
);
