import { configPostHog } from "./analytics/posthog";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IconSpinner } from "components/atoms/icons";
import "./index.css";
import posthog from "posthog-js";
import { CandidacyTypeEnum } from "chunks/candidatura";
configPostHog(posthog);

const PageCandidatura = lazy(
  () => import(/* webpackChunkName: "candidatura" */ "./chunks/candidatura")
);

const PageBackoffice = lazy(
  () => import(/* webpackChunkName: "backoffice" */ "./chunks/backoffice")
);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<IconSpinner />}>
        <Routes>
          <Route path="/backoffice/*" element={<PageBackoffice />}>
            <Route path="*" element={<PageBackoffice />} />
          </Route>

          <Route
            path="/company/*"
            element={<PageCandidatura type={CandidacyTypeEnum.COMPANY} />}
          />
          <Route
            path="/*"
            element={<PageCandidatura type={CandidacyTypeEnum.CESARINA} />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
