import { forwardRef } from "react";

import { Error } from "components/atoms/form/field-error";
import { InputFieldProps } from "components/atoms/form/input";

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  ({ label, ...props }, ref) => {
    return (
      <div className="flex-1 p-2 space-y-1 focus-within:ring-2 focus-within:ring-inset focus-within:ring-brandYellow">
        <label className="flex flex-col">
          <span className="text-sm text-gray-500 cursor-pointer">
            {label}
          </span>
          <input ref={ref} type="text" data-testid={props.name} {...props} className="text-gray-900 outline-none" />
        </label>
        {props.error && (
          <Error>{props.error}</Error>
        )}
      </div>
    );
  }
);
