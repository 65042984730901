import { forwardRef, FC, ChangeEvent, useState, useRef } from "react";
import cssClass from "classnames";
import usePlacesAutocomplete, { Suggestion } from "use-places-autocomplete";
import useClickOut from "@bscop/use-click-out";
import useScript from "react-script-hook";

import { Error } from "./field-error";
import { getCoordinates } from "utils/geo/get-coordinates";

export type Coordinates = {
  latitude : number;
  longitude : number;
};

type InputFieldAddressProps = {
  error ?: string;
  label : string;
  name : string;
  onChange : (value : Suggestion, coordinates : Coordinates) => void;
  readOnly ?: boolean;
  value ?: string;
};

export const InputFieldAddress = forwardRef<HTMLInputElement, InputFieldAddressProps>(
  (props, ref) => {
    const loadedAlready = !!window.google?.maps;
    const [ready, setReady] = useState(loadedAlready);

    useScript({
      src: loadedAlready
        ? null
        : `https://maps.googleapis.com/maps/api/js?key=${window.ENV.REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places&language=it`,
      onload: () => setReady(true),
    });

    return ready ? <GoogleAutocomplete ref={ref} {...props} /> : null;
  }
);

const GoogleAutocomplete = forwardRef<HTMLInputElement, InputFieldAddressProps>(
  ({ label, onChange, value: initialValue, ...props }, ref) => {
    const {
      clearSuggestions,
      ready,
      setValue,
      suggestions,
      value,
    } = usePlacesAutocomplete({
      requestOptions: {
        /**
         * More info about what can we use here can be found at:
         * https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
         */
        componentRestrictions: {
          country: "it",
        },
      },
      debounce: 250,
    });

    const boxRef = useClickOut<HTMLDivElement>(
      () => {
        clearSuggestions();
      },
      suggestions.status === "OK" && suggestions.data.length > 0
    );

    const timeRef = useRef(null);

    const onBlur = () => {
      /**
       * When user exit the autocomplete input without selecting any suggested value
       * we want to clear the text they wrote in the input.
       * The problem is that we don't know immediately whether the user exited
       * the input to select a suggestion, or to focus a completely different part
       * of the page.
       * So here, we start a timer, and after user leave the input
       * we clear the input only if the selection doesn't happen.
       * In `onSelect` function we clear this timer, so that we don't clear the input
       * when is not needed.
       */
      timeRef.current = setTimeout(
        () => {
          setValue("", /* shouldFetchData */ false);
        },
        250
      );
    };

    const onSelect = async (suggestion) => {
      if (timeRef.current) {
        clearTimeout(timeRef.current);
        timeRef.current = null;
      }

      clearSuggestions();

      setValue(suggestion.description, /* shouldFetchData */ false);

      const coords = await getCoordinates(suggestion);

      onChange(suggestion, { latitude: coords.lat, longitude: coords.lng });
    };

    const resetSelection = () => {
      setValue("", /* shouldFetchData */ false);

      onChange(null, null);
    };

    return (
      <div ref={boxRef} className="relative flex-1 space-y-1">
        <label className="flex flex-col space-y-1">
          <span className="text-sm cursor-pointer">
            {label}
          </span>
          <Input
            ref={ref}
            {...props}
            disabled={!ready}
            onBlur={onBlur}
            onChange={
              (event) => {
                const value = event.target.value;

                if (value) {
                  setValue(value);
                } else {
                  resetSelection();
                }
              }
            }
            value={value || initialValue}
          />
        </label>
        {suggestions.status === "OK" && (
          <Results onSelect={onSelect} suggestions={suggestions.data} />
        )}
        {props.error && (
          <Error>{props.error}</Error>
        )}
      </div>
    );
  }
);

type InputProps = {
  disabled ?: boolean;
  error ?: string;
  name : string;
  onBlur : () => void;
  onChange : (event : ChangeEvent<HTMLInputElement>) => void;
  readOnly ?: boolean;
  value ?: string;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    return (
      <input
        ref={ref}
        {...props}
        type="text"
        className={
          cssClass(
            {
              "border-red-700": props.error,
              "text-gray-500 outline-none": props.readOnly,
            },
            "p-2 border rounded"
          )
        }
      />
    );
  }
);

type ResultsProps = {
  onSelect: (suggestion : Suggestion) => Promise<void>;
  suggestions: Suggestion[];
};

const Results : FC<ResultsProps> = ({ onSelect, suggestions }) => {
  return (
    <div className="absolute z-10 w-full p-0 bg-white border rounded shadow-md">
      <ul className="py-1 divide-y">
        {suggestions.map(
          (suggestion) => {
            const { place_id, structured_formatting: { main_text, secondary_text } } = suggestion;
            return (
              <li key={place_id} className="px-2 py-1">
                <button className="block w-full overflow-x-hidden text-left whitespace-nowrap text-ellipsis" type="button" onClick={() => onSelect(suggestion)}>
                  <strong>{main_text}</strong> <span className="text-sm">{secondary_text}</span>
                </button>
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
};
