import { forwardRef } from "react";
import cssClass from "classnames";

import { Error } from "./field-error";

export type InputFieldProps = {
  error ?: string;
  label : string;
  name : string;
  prefix : string;
  readOnly ?: boolean;
  type ?: string;
  value ?: string;
};

export const InputPhoneField = forwardRef<HTMLInputElement, InputFieldProps>(
  ({ label, error, prefix, ...props }, ref) => {
    return (
      <div className="flex-1 space-y-1">
        <label className="flex flex-col space-y-1">
          <span className="text-sm cursor-pointer">
            {label}
          </span>
          <div className="flex items-center space-x-2">
            <span>{prefix}</span>
            <input
              ref={ref}
              data-testid={props.name}
              type="text"
              {...props}
              className={cssClass({ "border-red-700": error }, "p-2 border rounded")}
            />
          </div>
        </label>
        {error && (
          <Error>{error}</Error>
        )}
      </div>
    );
  }
);
