import {IAnalyticsTracker, SetUserInput} from "./IAnalyticsTracker";
import ReactGA from "react-ga4";

export class AdwordsTracker implements IAnalyticsTracker {
  candidacyRejected(): void {
      //void
  }
  candidacyApproved(): void {
      //void
  }
  setUser(data: SetUserInput): void {
      //void
  }
  beginCandidacy(): void {
    //not implemented
  }

  certificatesStepCompleted(): void {
    //not implemented
  }

  cuisineInfoStepCompleted(): void {
    //not implemented
  }

  cuisinePhotosStepCompleted(): void {
    //not implemented
  }

  endCandidacy(): Promise<void> {
    if (!ReactGA.isInitialized) {
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      ReactGA.gtag("event", "conversion", {
        "send_to": `${window.ENV.REACT_APP_GOOGLE_ADS_PROPERTY_ID}/${window.ENV.REACT_APP_GOOGLE_ADS_CANDIDACY_COMPLETED_EVENT}`,
        "event_callback": () => resolve(),
      });
    });
  }

  extraEarnStepCompleted(): void {
    //not implemented
  }

  homeAccessibilityStepCompleted(): void {
    //not implemented
  }

  homeInfoStepCompleted(): void {
    //not implemented
  }

  homePhotosStepCompleted(): void {
    //not implemented
  }

  locationStepCompleted(): void {
    //not implemented
  }

  profileStepCompleted(): void {
    //not implemented
  }

  proposalStepCompleted(): void {
    //not implemented
  }
}
