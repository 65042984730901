import React, { FC, Fragment } from "react";
import { Dialog as DialogUI, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import cssClass from "classnames";

import { IconCross } from "components/atoms/icons";

export type DialogProps = {
  isOpen : boolean;
  close ?: () => void;
  renderCrossButton ?: boolean;
  size ?: string;
  title ?: string;
};

export const Dialog : FC<DialogProps> =
  ({ children, close, isOpen, renderCrossButton = true, size, title }) => {
    const { t } = useTranslation();
    return (
      <Transition appear show={isOpen} as={Fragment}>
        <DialogUI className="fixed inset-0 z-50 overflow-y-auto" onClose={close}>
          <div className="min-h-screen px-4 text-center">
            <FadeIn>
              <DialogUI.Overlay className="fixed inset-0 bg-overlay" />
            </FadeIn>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <FadeIn>
              <DialogContent className={size || "max-w-md"}>
                <div className="flex flex-col">
                  <div className="flex justify-between mb-2 align-items">
                    <DialogUI.Title as="h3" className="text-xl font-semibold">
                      {title}
                    </DialogUI.Title>
                    {renderCrossButton && (
                      <button type="button" onClick={close} aria-label={t("ctaCloseDialog")} className="p-1 text-2xl rounded hover:bg-gray-100 group">
                        <IconCross aria-hidden="true" />
                      </button>
                    )}
                  </div>
                  {children}
                </div>
              </DialogContent>
            </FadeIn>
          </div>
        </DialogUI>
      </Transition>
    );
  };

const FadeIn : FC =
  ({ children }) => {
    return (
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {children}
      </Transition.Child>
    );
  };

type DialogContentProps = {
  children : React.ReactNode;
  className : string;
};

const DialogContent = React.forwardRef<HTMLDivElement, DialogContentProps>(
  function ({ children, className }, ref) {
    return (
      <div
        ref={ref}
        className={
          cssClass(
            className,
            "inline-block w-full p-3 my-8 overflow-hidden text-left align-middle border border-gray-100 transition-all transform bg-white shadow-xl rounded-md"
          )
        }
      >
        {children}
      </div>
    );
  }
);
