import { FC, useCallback, useEffect, useRef, useState } from "react";
import { nanoid } from "nanoid";
import useBool from "@bscop/use-bool";

import { CandidacyStatus } from "graphql/types";

import { useStepLocation } from "hooks/use-step-location";

import { IconSpinner } from "components/atoms/icons";
import { FormLocation } from "components/organisms/form-location";

import { Layout } from "./atoms/layout";

import { useAuth0AccountLinking } from "../../../hooks/use-auth0-account-linking";
import { AnalyticsTrackerComposite } from "../../../analytics/AnalyticsTrackerComposite";
import { CandidacyType } from "chunks/candidatura";

export const StepLocation: FC = () => {
  const { data, isLoading } = useStepLocation();

  const [requestPending, onRequestStart, onRequestEnd] = useBool(false);

  const [disabled, setDisabled] = useState(true);
  const onValidation = useCallback((isValid) => setDisabled(!isValid), []);

  const refFormId = useRef("_" + nanoid());

  useAuth0AccountLinking();
  useEffect(() => {
    AnalyticsTrackerComposite.instance().beginCandidacy();
  }, []);

  if (isLoading) {
    return (
      <Layout disabled isLoading>
        <div className="flex justify-center mt-10">
          <IconSpinner />
        </div>
      </Layout>
    );
  }

  const {
    errors,
    name,
    navigation,
    calculatedStatus,
    candidacyType,
    ...initialValues
  } = data;

  const readOnly =
    calculatedStatus === CandidacyStatus.Completed ||
    calculatedStatus === CandidacyStatus.Accepted;

  return (
    <Layout
      calculatedStatus={calculatedStatus}
      disabled={disabled}
      errors={errors}
      formId={refFormId.current}
      isReturningCandidate={
        !!(name || Object.values(initialValues).some(Boolean))
      }
      name={name}
      navigation={navigation}
      readOnly={readOnly}
      requestPending={requestPending}
    >
      <FormLocation
        candidacyType={candidacyType as CandidacyType}
        id={refFormId.current}
        initialValues={initialValues}
        onRequestEnd={onRequestEnd}
        onRequestStart={onRequestStart}
        onValidation={onValidation}
        proceedOnSuccess={navigation.nextStep.status !== "COMPLETE"}
        readOnly={readOnly}
        onSuccess={() =>
          AnalyticsTrackerComposite.instance().locationStepCompleted()
        }
      />
    </Layout>
  );
};
