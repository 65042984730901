import { UserSteps } from "graphql/types";

export const MAP_STEP_ROUTE = new Map([
  [UserSteps.Location, "/location"],
  [UserSteps.Home, "/home"],
  [UserSteps.Accessibility, "/accessibility"],
  [UserSteps.Homeinfo, "/home-info"],
  [UserSteps.Cusine, "/cusine"],
  [UserSteps.Cusineinfo, "/cusine-info"],
  [UserSteps.Proposals, "/proposals"],
  [UserSteps.Profile, "/profile"],
  [UserSteps.Certificates, "/certificates"],
  [UserSteps.Extra, "/extra"],
]);
