import { forwardRef } from "react";
import { ChangeHandler } from "react-hook-form";
import cssClass from "classnames";

import { Error } from "./field-error";

type Option = {
  label: string;
  value: string;
};

type SelectProps = {
  error?: string;
  label?: string;
  name: string;
  onChange: ChangeHandler;
  options: Option[];
  readOnly?: boolean;
};

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ error, label, options, readOnly, ...props }, ref) => {
    // According to HTML specs, select doesn't have a readonly attribute.
    // So, the alternative, is to use disabled attribute.
    return (
      <div className="flex-1 space-y-1">
        <label className="flex flex-col space-y-1">
          <span className="text-sm cursor-pointer">{label}</span>
          <div className="p-2 border rounded">
            <select
              ref={ref}
              data-testid={props.name}
              disabled={readOnly}
              {...props}
              className={cssClass(
                { "border-red-700": error },
                "h-6 p-0 box-content w-[calc(100%-16px)] lg:w-[calc(100%-8px)] border-0 outline-none bg-white"
              )}
            >
              {options.map(({ label, value }) => {
                return (
                  <option key={value} value={value}>
                    {label}
                  </option>
                );
              })}
            </select>
          </div>
        </label>
        {error && <Error>{error}</Error>}
      </div>
    );
  }
);
