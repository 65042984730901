import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MoveStep } from "graphql/types";

import { useGender } from "hooks/use-gender";
import { DataProfile } from "hooks/use-step-profile";
import { useUpdateCandidacy } from "hooks/use-update-candidacy";

import { useCandidacy } from "state/candidacy";
import { setCompleted } from "state/candidacy/actions/set-completed";

import { FieldCol, FieldRow } from "components/atoms/form/grid";
import { InputField } from "components/atoms/form/input";
import { Select } from "components/atoms/form/select";
import { DialogFormError } from "components/molecules/dialog-form-error";

import { Avatar } from "./avatar";
import { addCompanySubstringToKey } from "utils/steps";
import { CandidacyType, CandidacyTypeEnum } from "chunks/candidatura";

type FormProps = {
  candidacyType?: CandidacyType | null;
  candidacyId?: string;
  id: string;
  initialValues: DataProfile;
  onRequestEnd: () => void;
  onRequestStart: () => void;
  onValidation: (isValid: boolean) => void;
  proceedOnSuccess?: boolean;
  readOnly?: boolean;
  onSuccess?: () => void;
};

export const FormProfile: FC<FormProps> = ({
  candidacyType,
  candidacyId,
  id,
  initialValues,
  onRequestEnd,
  onRequestStart,
  onValidation,
  proceedOnSuccess = true,
  readOnly,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const [candidacy, dispatch] = useCandidacy();

  const isCompanyFlow =
    candidacyType === CandidacyTypeEnum.COMPANY ||
    candidacy.type === CandidacyTypeEnum.COMPANY;

  const schema = useValidationSchema();

  const genders = useGender();

  const {
    formState: { errors, isValid },
    getValues,
    handleSubmit,
    register,
    setValue,
  } = useForm<DataProfile>({
    defaultValues: initialValues,
    delayError: 1000,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    onValidation(isValid);
  }, [isValid, onValidation]);

  const [operationError, setOperationError] = useState(null);
  const isDialogErrorOpen = operationError != null;

  const { mutate, isLoading } = useUpdateCandidacy(candidacyId, {
    onError() {
      setOperationError("UNKNOWN");
    },
    /**
     * candidacyId is set only when the component is rendered in backoffice area;
     * in this case we don't want to render the confirmation dialog.
     */
    onSuccess: candidacyId
      ? null
      : (res) => {
          if (res.error) {
            setOperationError(res.error);
            return;
          } else if (res.nextStep === "NONE") {
            dispatch(setCompleted(true));
          }
          onSuccess?.();
        },
    proceedOnSuccess,
  });

  useEffect(() => {
    if (isLoading) {
      onRequestStart();
    } else {
      onRequestEnd();
    }
  }, [isLoading, onRequestStart, onRequestEnd]);

  const onSubmit = (payload: DataProfile) => {
    mutate({
      ...payload,
      move: proceedOnSuccess ? MoveStep.Next : MoveStep.None,
      candidacyType: candidacyType ?? candidacy.type,
    });
  };

  return (
    <>
      <form
        className="flex flex-col flex-1"
        id={id}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <FieldCol>
          <div className="flex flex-col items-center space-y-5">
            <Avatar
              {...register("avatar")}
              onChange={(value) => {
                setValue("avatar", value, { shouldValidate: true });
              }}
              readOnly={readOnly}
              value={getValues("avatar")}
            />
            <div className="text-sm max-w-[75%] text-center">
              <span>
                {t(
                  addCompanySubstringToKey(
                    "step-profile.form.avatarDescription",
                    isCompanyFlow
                  )
                )}
              </span>
            </div>
          </div>
          <FieldRow>
            <InputField
              {...register("name")}
              error={errors.name?.message}
              label={t(
                addCompanySubstringToKey(
                  "step-profile.form.labelName",
                  isCompanyFlow
                )
              )}
              readOnly={readOnly}
            />
            <InputField
              {...register("surname")}
              error={errors.surname?.message}
              label={t(
                addCompanySubstringToKey(
                  "step-profile.form.labelSurname",
                  isCompanyFlow
                )
              )}
              readOnly={readOnly}
            />
          </FieldRow>
          <FieldRow>
            <Select
              {...register("gender")}
              error={errors.gender?.message}
              label={t("step-profile.form.labelGender")}
              options={genders}
              readOnly={readOnly}
            />
            <div className="flex-1" />
          </FieldRow>
        </FieldCol>
      </form>
      <DialogFormError
        close={() => {
          setOperationError(null);
        }}
        error={operationError}
        isOpen={isDialogErrorOpen}
      />
    </>
  );
};

const useValidationSchema = () => {
  const { t } = useTranslation();
  return yup.object().shape({
    avatar: yup.string().required(t("errorRequired")),
    gender: yup.string().required(t("errorRequired")),
    name: yup
      .string()
      .required(t("errorRequired"))
      .max(32, t("errorMaxLength", { count: 32 })),
    surname: yup
      .string()
      .required(t("errorRequired"))
      .max(32, t("errorMaxLength", { count: 32 })),
  });
};
