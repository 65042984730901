import { FC } from "react";

import { IconSpinner } from "components/atoms/icons";

export const CurrentStep: FC = () => {
  return (
    <div className="flex justify-center mt-5">
      <IconSpinner />
    </div>
  );
};
