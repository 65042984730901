import { useAuth0 } from "@auth0/auth0-react";
import { getSdk, UserSteps } from "graphql/types";
import { ClientError, GraphQLClient } from "graphql-request";
import { useQuery } from "react-query";
import { ENDPOINT } from "graphql/endpoint";
import { CandidacyTypeEnum } from "chunks/candidatura";

type CurrentStep = {
  currentStep: UserSteps;
  candidacyType?: string | null;
};
export const useGetCurrentStep = (fallbackType?: string) => {
  const { getAccessTokenSilently } = useAuth0();

  const getCurrentStep = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: window.ENV.REACT_APP_AUTH0_AUDIENCE,
    });

    const client = new GraphQLClient(ENDPOINT);
    const sdk = getSdk(client);

    try {
      const data = await sdk.GetCurrentStep(null, {
        authorization: `Bearer ${accessToken}`,
      });
      return data.getCurrentStep;
    } catch (error) {
      return {
        currentStep: UserSteps.Location,
        candidacyType: fallbackType ?? CandidacyTypeEnum.CESARINA,
      } as CurrentStep;
    }
  };

  return useQuery<CurrentStep, ClientError>("current-step", getCurrentStep, {
    cacheTime: 0,
  });
};
