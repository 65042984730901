import { useAuth0 } from "@auth0/auth0-react";
import {
  getSdk,
  UpdateCandidacyInput,
  UpdateCandidacyOutput,
} from "graphql/types";
import { ClientError, GraphQLClient } from "graphql-request";
import { useMutation } from "react-query";
import { ENDPOINT } from "graphql/endpoint";
import { useNavigate } from "react-router-dom";

import { MAP_STEP_ROUTE } from "routes";
import { useCandidacyCompanyFlow } from "state/candidacy";

type HandlerSuccess = (response: UpdateCandidacyOutput) => void;
type HandlerError = (error: ClientError) => void;

type Settings = {
  onError?: HandlerError;
  onSuccess?: HandlerSuccess;
  proceedOnSuccess?: boolean;
};

export const useUpdateCandidacy = (
  candidacyId?: string,
  settings?: Settings
) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const isCompanyFlow = useCandidacyCompanyFlow();

  const updateCandidacy = async (payload: UpdateCandidacyInput) => {
    const accessToken = await getAccessTokenSilently({
      audience: window.ENV.REACT_APP_AUTH0_AUDIENCE,
    });

    const client = new GraphQLClient(ENDPOINT);

    const sdk = getSdk(client);

    const headers: Record<string, string> = {
      authorization: `Bearer ${accessToken}`,
    };

    if (candidacyId) {
      headers.candidacyId = candidacyId;
    }

    try {
      const data = await sdk.UpdateCandidacy({ data: payload }, headers);

      return data.updateCandidacy;
    } catch (error) {
      return null;
    }
  };

  return useMutation<UpdateCandidacyOutput, ClientError, UpdateCandidacyInput>(
    updateCandidacy,
    {
      onError: (error) => {
        if (typeof settings?.onError == "function") {
          settings.onError(error);
        }
      },
      onSuccess: (response) => {
        if (typeof settings?.onSuccess == "function") {
          settings.onSuccess(response);
        }
        if (
          settings.proceedOnSuccess &&
          MAP_STEP_ROUTE.has(response.nextStep)
        ) {
          navigate(
            `${isCompanyFlow ? "/company" : ""}${MAP_STEP_ROUTE.get(
              response.nextStep
            )}`
          );
        }
      },
    }
  );
};
